/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { addYears, endOfDay, startOfDay, subMonths } from 'date-fns';
import { useLocation } from 'react-router-dom';
import InputDate from '../InputDate';
import Select, { IOption } from '../Select';

import { Container } from './styles';
import Input from '../Input';
import { KrFilter } from '../KoroIcons';

interface IFormData {
  [key: string]: any;
}

interface IFilters {
  onSubmitFilters(data: IFormData): void;
  event?: boolean;
  name?: boolean;
  email?: boolean;
  city?: boolean;
  state?: boolean;
  score?: boolean;
  organizer?: boolean;
  category?: boolean;
  categories?: IOption[];
  modality?: boolean;
  modalities?: IOption[];
  status?: boolean;
  statusEvent?: boolean;
  statusPayment?: boolean;
  statusTicket?: boolean;
  statusOptions?: IOption[];
  date?: boolean;
  notAllStatus?: boolean;
}

const FiltersLists: React.FC<IFilters> = ({
  onSubmitFilters,
  event,
  name,
  email,
  city,
  state,
  score,
  organizer,
  category,
  categories: categoriesData,
  modality,
  modalities: modalitiesData,
  status,
  statusEvent,
  statusPayment,
  statusTicket,
  statusOptions,
  date,
  notAllStatus,
}) => {
  const location = useLocation();
  const formRef = useRef<FormHandles>(null);
  const [startDate, setStartDate] = useState<Date>(
    startOfDay(subMonths(new Date(), 6))
  );
  const [endDate, setEndDate] = useState<Date>(
    endOfDay(addYears(new Date(), 1))
  );
  const [categories, setCategories] = useState<IOption[]>([]);
  const [statusData, setStatusData] = useState<IOption[]>([]);
  const [modalities, setModalities] = useState<IOption[]>([]);

  useEffect(() => {
    if (categoriesData) {
      setCategories([
        {
          id: 'todos',
          value: 'Todos',
          selected: true,
        },
        ...categoriesData,
      ]);
    }
  }, [categoriesData]);

  useEffect(() => {
    if (modalitiesData) {
      setModalities([
        {
          id: 'todas',
          value: 'Todas',
          selected: true,
        },
        ...modalitiesData,
      ]);
    }
  }, [modalitiesData]);

  useEffect(() => {
    if (statusOptions) {
      if (notAllStatus) {
        setStatusData([...statusOptions]);
      } else {
        setStatusData([
          {
            id: 'todos',
            value: 'Todos',
            selected: true,
          },
          ...statusOptions,
        ]);
      }
    }
  }, [notAllStatus, statusOptions]);

  return (
    <Container className="">
      <Form ref={formRef} onSubmit={onSubmitFilters}>
        <div className="bg-filter d-lg-flex">
          {!location.pathname.includes('/financeiro') && (
            <div className="text-end">
              <h2 className="h5 fw-semibold d-flex align-items-center mb-0">
                <KrFilter size={24} color="#000" className="me-2" />
                Filtros
              </h2>
              <button
                type="button"
                onClick={() => window.location.reload()}
                className="btn-clear mt-2 border-0 bg-transparent"
              >
                Limpar
              </button>
            </div>
          )}

          {name && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Usuário</span>
              <Input className="height" name="name" type="text" />
            </div>
          )}

          {email && (
            <div className="px-lg-3">
              <span className="d-block mb-1">E-mail</span>
              <Input className="height" name="email" />
            </div>
          )}

          {city && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Cidade</span>
              <Input className="height" name="city" type="text" />
            </div>
          )}

          {state && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Estado</span>
              <Input className="height" name="state" type="text" />
            </div>
          )}

          {organizer && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Organizador</span>
              <Input className="height" name="organizer" type="text" />
            </div>
          )}

          {event && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Evento</span>
              <Input name="event" className="height" />
            </div>
          )}

          {statusEvent && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Status evento</span>
              <Select
                name="status"
                className="bd-select"
                options={statusData}
              />
            </div>
          )}

          {statusPayment && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Status pagamento</span>
              <Select
                name="statusPayment"
                className="bd-select"
                options={statusData}
              />
            </div>
          )}

          {statusTicket && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Status ingresso</span>
              <Select
                name="statusTicket"
                className="bd-select"
                options={statusData}
              />
            </div>
          )}

          {status && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Ativo</span>
              <Select
                name="status"
                className="bd-select"
                options={[
                  {
                    id: 'active',
                    value: 'Ativo',
                    selected: true,
                  },
                  {
                    id: 'not-active',
                    value: 'Inativo',
                    selected: false,
                  },
                ]}
              />
            </div>
          )}

          {score && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Score</span>
              <Select
                name="score"
                className="bd-select"
                options={[
                  {
                    id: 'all',
                    value: 'Score',
                    selected: true,
                  },
                  {
                    id: 'event1',
                    value: 'Evento1',
                    selected: false,
                  },
                  {
                    id: 'event2',
                    value: 'Evento2',
                    selected: false,
                  },
                ]}
              />
            </div>
          )}

          {category && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Esportes</span>
              <Select
                name="sports"
                className="bd-select mt-0"
                options={categories}
              />
            </div>
          )}

          {modality && (
            <div className="px-lg-3">
              <span className="d-block mb-1">Modalidade</span>
              <Select
                name="modality"
                className="bd-select mt-0"
                options={modalities}
              />
            </div>
          )}

          {date && (
            <>
              <div className="px-lg-3">
                {!location.pathname.includes('/financeiro') && (
                  <span className="mb-1 d-block">Data</span>
                )}

                <InputDate
                  name="dateStart"
                  selected={startDate}
                  colorSvg="#5D5D5D"
                  onChange={(e) => setStartDate(e as Date)}
                />
              </div>
              <div className="d-flex">
                <span className="small d-block mb-1 lh-lg mt-auto">Até</span>
              </div>
              <div className="px-lg-3 d-flex flex-column justify-content-end">
                <InputDate
                  name="dateEnd"
                  selected={endDate}
                  colorSvg="#5D5D5D"
                  className="mt-auto"
                  onChange={(e) => setEndDate(e as Date)}
                />
              </div>
            </>
          )}

          <button type="submit" className="btn-filter rounded px-4 py-1">
            Filtrar
          </button>
        </div>
      </Form>
    </Container>
  );
};

export default FiltersLists;
