import React, { createContext, useCallback, useState, useContext } from 'react';
import Swal from 'sweetalert2';

import api from '../services/api';

interface IModule {
  id: number;
  module_id: number;
  type: string;
  title: string;
  link: string;
  icon: string;
  level1: number;
  level2?: number;
  level3?: number;
  subModules: IModule[];
  active?: boolean;
}

export interface ISetting {
  module: IModule;
}

export interface IUser {
  id: number;
  name: string;
  email: string;
  profile: {
    settings: ISetting[];
  };
  organizer: {
    id: number;
  };
}

interface AuthState {
  token: string;
  user: IUser;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Koro:token');
    const user = localStorage.getItem('@Koro:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    try {
      const response = await api.post<AuthState>(
        `users/sessions`,
        {
          email,
          password,
        },
        {
          params: {
            environment: 'Organizador',
          },
        }
      );

      const { token, user } = response.data;

      localStorage.setItem('@Koro:token', token);
      localStorage.setItem('@Koro:user', JSON.stringify(user));

      api.defaults.headers.authorization = `Bearer ${token}`;

      setData({
        token,
        user,
      });
    } catch (error) {
      Swal.fire('Opss...', 'Código de autenticação inválido', 'error');
    }
  }, []);

  const signOut = useCallback(async () => {
    localStorage.removeItem('@Koro:token');
    localStorage.removeItem('@Koro:user');

    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: IUser) => {
      localStorage.setItem('@Koro:user', JSON.stringify(user));

      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token]
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
