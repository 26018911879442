import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { Link } from 'react-router-dom';

import { Container } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logos/logo-name.svg';

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(() => {
    // console.log('test');
  }, []);

  return (
    <Container>
      <div className="container h-100">
        <div className="row justify-content-center">
          <div className="col-12 text-center">
            <div className="ret-up ret-left d-none d-lg-block" />
            <div className="ret-up ret-right d-none d-lg-block" />
            <img src={logo} alt="Logo Koro" className="logo" />
          </div>

          <div className="col-lg-4 px-3">
            <h2 className="h6 text-white fw-normal mb-3">
              Insira o e-mail de cadastro para redefinir sua senha
            </h2>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className="d-flex flex-column pb-3"
            >
              <label className="mb-3">
                <span className="text-white fw-normal d-block mb-2 size">
                  E-mail
                </span>
                <Input type="email" name="email" />
              </label>

              <p className="text-end small text-white">
                <Link
                  to={`${process.env.PUBLIC_URL}/`}
                  className="opacity-100 text-white"
                >
                  Lembrei a senha
                </Link>
              </p>

              <button
                type="submit"
                className="btn btn-login h6 fw-normal py-2 mt-4"
              >
                Enviar
              </button>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPassword;
