/* eslint-disable no-console */
import React from 'react';

import { Container } from './styles';

const Configurations: React.FC = () => {
  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <div className="bg-white d-flex justify-content-between align-items-center p-4">
              <h1 className="mb-0">Configurações</h1>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Configurations;
