import styled from 'styled-components';

export const Container = styled.div`
  .textarea .ql-toolbar.ql-snow {
    border-radius: 4px 4px 0px 0px;
  }

  .textarea .ql-container.ql-snow {
    border-radius: 0px 0px 4px 4px;
  }

  .textarea .ql-editor {
    height: 200px;
  }
`;
