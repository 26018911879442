/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import axios from 'axios';

import { addHours } from 'date-fns';
import ReactQuill from 'react-quill';
import { Container } from './styles';
import Input from '~/components/Input';
import Select, { IOption } from '~/components/Select';
import InputMask from '~/components/InputMask';
import InputDate from '~/components/InputDate';
import Textarea from '~/components/Textarea';
import api from '~/services/api';

interface IType {
  id: number;
  name: string;
}

interface IStatus {
  id: number;
  name: string;
}

interface IBasicData {
  onChangeEventName(value: string): void;
  onChangeType(option: IOption): void;
  onChangeStatus(option: IOption): void;
  className: string;
  initialData?: {
    [key: string]: any;
  };
}

const BasicData: React.FC<IBasicData> = ({
  onChangeEventName,
  onChangeType,
  onChangeStatus,
  className,
  initialData,
}) => {
  const [types, setTypes] = useState<IOption[]>([]);
  const [status, setStatus] = useState<IOption[]>([]);
  const [date, setDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [salesEndDate, setSalesEndDate] = useState<Date>(new Date());
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [number, setNumber] = useState('');
  const [description, setDescription] = useState('');

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // Tamanhos de cabeçalhos
      ['bold', 'italic', 'underline'], // Estilos de texto
      [{ list: 'ordered' }, { list: 'bullet' }], // Listas
      [{ align: [] }], // Alinhamento
    ],
  };

  useEffect(() => {
    api.get<IStatus[]>('events-status').then((response) => {
      let data = response.data.map<IOption>((statusData) => ({
        id: statusData.id,
        value: statusData.name,
        selected: !!(
          initialData && parseInt(initialData.status, 10) === statusData.id
        ),
      }));

      const statusSelected = data.find((statusData) => statusData.selected);

      if (
        statusSelected?.id === 1 ||
        statusSelected?.id === 2 ||
        statusSelected?.id === 7
      ) {
        data = [statusSelected];
      } else {
        data = data.filter(
          (statusData) =>
            statusData.id !== 1 && statusData.id !== 2 && statusData.id !== 7
        );
      }

      setStatus(data);
    });
  }, [initialData]);

  useEffect(() => {
    if (initialData) {
      setDate(initialData.date);
      setDescription(initialData.description);
      setEndDate(initialData.end_date);
      setSalesEndDate(initialData.sales_end_date);
      setState(initialData.state);
      setZipCode(initialData.zipcode);
      setCity(initialData.city);
      setAddress(initialData.address);
      setNeighborhood(initialData.neighborhood);
      setNumber(initialData.number);
    }
  }, [initialData]);

  const handleChangeType = useCallback(
    (option) => {
      onChangeType(option);
    },
    [onChangeType]
  );

  const handleChangeStatus = useCallback(
    (option) => {
      onChangeStatus(option);
    },
    [onChangeStatus]
  );

  const handleLoadTypes = useCallback(
    async (search = '') => {
      const response = await api.get<IType[]>('events-types', {
        params: { search, isOrganizer: true },
      });
      const data = response.data.map<IOption>((type) => ({
        id: type.id,
        value: type.name,
        selected: !!(initialData && parseInt(initialData.type, 10) === type.id),
      }));
      setTypes(data);
      const optionSelected = data.find((type) => type.selected);
      if (optionSelected) {
        handleChangeType(optionSelected);
      }
    },
    [handleChangeType, initialData]
  );

  useEffect(() => {
    handleLoadTypes();
  }, [handleLoadTypes]);

  const handleChangeDate = useCallback((value) => {
    setDate(value);
  }, []);

  const handleChangeZipCode = useCallback(async (e) => {
    const { value } = e.target;
    if (value.length === 9) {
      const response = await axios.get(
        `https://viacep.com.br/ws/${value}/json/`
      );
      setZipCode(response.data.cep);
      setState(response.data.uf);
      setCity(response.data.localidade);
      setAddress(response.data.logradouro);
      setNeighborhood(response.data.bairro);
    }
  }, []);

  const handleDescription = useCallback((e) => {
    setDescription(e);
  }, []);

  const handleState = useCallback((e) => {
    setState(e.target.value);
  }, []);

  const handleCity = useCallback((e) => {
    setCity(e.target.value);
  }, []);

  const handleAddress = useCallback((e) => {
    setAddress(e.target.value);
  }, []);

  const handleNeighborhood = useCallback((e) => {
    setNeighborhood(e.target.value);
  }, []);

  const handleNumber = useCallback((e) => {
    setNumber(e.target.value);
  }, []);

  const addressMap = useMemo(() => {
    return address
      ? `${address} n˚ ${number}, ${neighborhood} - ${city}/${state}, CEP: ${zipCode}`
      : 'Brazil';
  }, [address, city, neighborhood, number, state, zipCode]);

  const handleChangeEndDate = useCallback((value) => {
    setEndDate(value);
  }, []);

  const handleChangeSalesEndDate = useCallback((value) => {
    setSalesEndDate(value);
  }, []);

  return (
    <Container className={className}>
      <div className="col-12">
        <h2 className="h4 fw-semibold text-center mt-5">1- Dados básicos</h2>
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Nome do evento</h3>
        <Input
          name="name"
          type="text"
          onChange={(e) => onChangeEventName(e.target.value)}
          className="input"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Esporte</h3>
        <Select
          name="type"
          className="input"
          options={types}
          onChange={handleChangeType}
          placeholder="Selecione"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Data Inicio</h3>
        <InputDate
          className="py-1"
          name="date"
          selected={addHours(date, 3)}
          onChange={handleChangeDate}
          colorSvg="#5D5D5D"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Data Fim</h3>
        <InputDate
          className="py-1"
          name="end_date"
          selected={endDate}
          onChange={handleChangeEndDate}
          colorSvg="#5D5D5D"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Fim das Vendas</h3>
        <InputDate
          className="py-1"
          name="sales_end_date"
          selected={salesEndDate}
          onChange={handleChangeSalesEndDate}
          colorSvg="#5D5D5D"
        />
      </div>
      <div className="col-lg-2 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Status</h3>
        <Select
          name="status"
          className="input"
          options={status}
          onChange={handleChangeStatus}
          placeholder="Selecione"
          disabled={status.length <= 1}
        />
      </div>
      <div className="col-lg-4 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">País</h3>
        <Input
          value="Brasil"
          name="country"
          type="text"
          className="pe-none input"
        />
      </div>
      <div className="col-lg-4 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">CEP</h3>
        <InputMask
          kind="custom"
          options={{ mask: '99999-999' }}
          name="zipcode"
          value={zipCode}
          onChange={handleChangeZipCode}
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Estado</h3>
        <Input
          name="state"
          onChange={handleState}
          value={state}
          type="text"
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Cidade</h3>
        <Input
          name="city"
          value={city}
          onChange={handleCity}
          type="text"
          className="input"
        />
      </div>
      <div className="col-lg-12 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Endereço</h3>
        <Input
          name="address"
          value={address}
          onChange={handleAddress}
          type="text"
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Bairro</h3>
        <Input
          name="neighborhood"
          type="text"
          value={neighborhood}
          onChange={handleNeighborhood}
          className="input"
        />
      </div>
      <div className="col-lg-6 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Número</h3>
        <Input
          name="number"
          value={parseInt(number, 10) === 0 ? 'S/N' : number}
          onChange={handleNumber}
          className="input"
        />
      </div>
      <div className="col-12 px-lg-2 mt-3">
        <iframe
          src={`https://www.google.com/maps/embed/v1/place?key=${
            process.env.REACT_APP_MAPS_KEY
          }&q=${addressMap}&zoom=${addressMap === 'Brazil' ? 2 : 15}`}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="map"
        />
      </div>
      <div className="col-12 px-lg-2 mt-3">
        <h3 className="h6 fw-normal">Descrição</h3>
        <ReactQuill
          className="textarea"
          value={description}
          onChange={handleDescription}
          theme="snow"
          modules={modules}
        />
        <Input
          type="hidden"
          name="description"
          className="d-none"
          value={description}
        />
      </div>
    </Container>
  );
};

export default BasicData;
