import styled from 'styled-components';

interface IStatus {
  status: string;
}

export const Container = styled.div`
  min-width: calc(100% - 88px);

  .infinite-scroll {
    height: 100vh;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 0;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent !important;
    }
  }

  h1 {
    font-size: 26px;
    font-weight: 500;
  }

  hr {
    background: #e1e1e1;
    opacity: 1;
  }

  a {
    :hover {
      color: unset;
    }
  }

  .container-fluid {
    max-width: 1460px;
  }

  .btn-new {
    border-radius: 4px;
    background: #ff6900;
    border: none;
  }

  .bg-white {
    border-radius: 8px;
  }

  .tooltip-event:after {
    background-color: #002f6c;
    color: #fff;
    border: 1px solid #002f6c;
    border-radius: 15px;
    text-align: start;
    box-sizing: border-box;
    content: attr(data-tooltip);
    display: none;
    padding: 15px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    position: absolute;
    bottom: 30px;
    left: -163px;
    width: 210px;
    z-index: 10000;
  }

  .tooltip-event:hover:after {
    display: block;
  }
`;

export const Cards = styled.div`
  h3 {
    color: #5d5d5d;
    font-size: 14px;
    font-weight: 400;
  }

  p {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }

  .default {
    max-width: 64px;
    max-height: 64px;
    border: solid 1px #002f6c;
    border-radius: 4px;
  }

  .bg-orange {
    background-color: #ff842f;
    padding: 4px 8px;
    height: 32px;
    width: 32px;
    display: inline-block;
  }
`;

export const Filter = styled.div`
  h2 {
    color: #000;
    font-family: 'Roboto';
  }
`;

export const Status = styled.p<IStatus>`
  background-color: ${(props) => {
    switch (props.status) {
      case 'Rascunho':
        return '#4b4b4d';

      case 'Pendente':
        return '#dbb100';
      case 'Aprovado':
        return '#dbb100';
      case 'Aberto':
        return '#427242';
      case 'Esgotado':
        return '#427242';
      case 'Fechado':
        return '#427242';
      case 'Em Andamento':
        return '#427242';
      case 'Encerrado':
        return '#002f6c';
      case 'Finalizado':
        return '#002f6c';
      case 'Cancelado':
        return '#bf2216';
      default:
        return '#000';
    }
  }};
  padding: 3px;
`;
