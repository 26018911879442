import React from 'react';
import { IProps } from './interfaces';

const KrContract: React.FC<IProps> = ({
  size = 32,
  color = '#FF6900',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5856_16)">
        <path
          d="M21.2 0.0300293H11C10.25 0.0300293 9.54 0.340029 9.02 0.880029L0.669998 9.69003C0.189998 10.2 -0.0800018 10.86 -0.0800018 11.56V29.25C-0.0800018 30.75 1.15 31.97 2.65 31.97H21.2C22.7 31.97 23.92 30.75 23.92 29.25V21.15L22.12 22.95V29.25C22.12 29.76 21.71 30.17 21.2 30.17H2.65C2.14 30.17 1.73 29.76 1.73 29.25V11.56C1.73 11.33 1.81 11.1 1.98 10.93L10.33 2.12003C10.5 1.93003 10.75 1.83003 11 1.83003H21.2C21.71 1.83003 22.12 2.24003 22.12 2.75003V20.25L23.53 18.84L23.92 19.23V2.75003C23.92 1.25003 22.7 0.0300293 21.2 0.0300293Z"
          fill={color}
        />
        <path
          d="M17.76 18.87H5.48C5.15 18.87 4.88 18.6 4.88 18.27C4.88 17.94 5.15 17.67 5.48 17.67H17.76C18.09 17.67 18.36 17.94 18.36 18.27C18.36 18.6 18.09 18.87 17.76 18.87Z"
          fill={color}
        />
        <path
          d="M17.76 15.89H5.48C5.15 15.89 4.88 15.62 4.88 15.29C4.88 14.96 5.15 14.69 5.48 14.69H17.76C18.09 14.69 18.36 14.96 18.36 15.29C18.36 15.62 18.09 15.89 17.76 15.89Z"
          fill={color}
        />
        <path
          d="M10.37 12.46H0.83C0.33 12.46 -0.0699997 12.06 -0.0699997 11.56C-0.0699997 11.06 0.33 10.66 0.83 10.66H9.47V0.930029C9.47 0.430029 9.87 0.0300293 10.37 0.0300293C10.87 0.0300293 11.27 0.430029 11.27 0.930029V11.56C11.27 12.06 10.87 12.46 10.37 12.46Z"
          fill={color}
        />
        <path
          d="M14.5 27.28C13.86 27.28 12.92 27.25 11.56 27.22C10.79 27.2 10.57 26.75 10.41 26.43C10.29 26.18 10.16 25.9 9.72 25.57C9.6 25.48 9.51 25.48 9.44 25.5C9.02 25.61 8.49 26.4 8.24 26.93C8.12 27.18 7.83 27.29 7.58 27.18C7.33 27.06 7.22 26.77 7.33 26.52C7.41 26.34 8.14 24.8 9.19 24.53C9.58 24.43 9.97 24.51 10.31 24.77C10.94 25.25 11.16 25.7 11.31 26C11.35 26.08 11.4 26.18 11.42 26.21C11.42 26.21 11.46 26.23 11.58 26.23C13.98 26.3 14.85 26.29 15.16 26.28C15.25 26.24 15.35 26.22 15.45 26.24C15.71 26.28 15.96 26.53 15.94 26.8C15.91 27.18 15.9 27.3 14.5 27.3V27.28Z"
          fill={color}
        />
        <path
          d="M30.73 13.06L30.66 12.99C30.07 12.41 29.12 12.41 28.53 12.99L23.92 17.6L23.9 17.63L23.16 18.37L22.12 19.4L18.53 22.99C18.07 23.45 17.97 24.12 18.22 24.67C18.29 24.84 18.39 24.99 18.53 25.12L18.6 25.19C18.73 25.32 18.89 25.43 19.06 25.5C19.61 25.75 20.28 25.64 20.73 25.19L22.12 23.8L23.92 22L25.36 20.57L26.1 19.82L30.73 15.19C31.31 14.6 31.31 13.65 30.73 13.06ZM23.92 21.15L22.12 22.95L20.3 24.77C19.95 25.12 19.39 25.12 19.03 24.77H19.02C19.02 24.77 19 24.74 18.99 24.73C18.98 24.72 18.97 24.71 18.96 24.71C18.96 24.71 18.9567 24.7067 18.95 24.7C18.78 24.53 18.69 24.3 18.69 24.06C18.69 23.82 18.78 23.59 18.95 23.42L22.12 20.25L23.53 18.84L23.92 19.23L24.88 20.19L23.92 21.15ZM30.3 14.77L25.73 19.35L24.38 18L28.95 13.42C29.12 13.25 29.34 13.16 29.57 13.16C29.82 13.16 30.08 13.27 30.3 13.49C30.66 13.84 30.66 14.41 30.3 14.77Z"
          fill="white"
        />
        <path
          d="M31.08 12.57C30.26 11.75 28.93 11.75 28.11 12.57L23.92 16.76L22.12 18.56L18.11 22.57C17.43 23.25 17.31 24.29 17.78 25.09C17.86 25.26 17.97 25.41 18.11 25.55L18.17 25.61C18.31 25.75 18.46 25.87 18.62 25.95C18.94 26.14 19.3 26.23 19.66 26.23C20.2 26.23 20.74 26.03 21.15 25.61L22.12 24.64L23.92 22.84L31.15 15.61C31.97 14.79 31.97 13.46 31.08 12.57ZM24.88 20.19L23.92 21.15L22.12 22.95L20.3 24.77C19.95 25.12 19.39 25.12 19.03 24.77H19.02L18.96 24.71C18.96 24.71 18.96 24.7 18.95 24.7C18.78 24.53 18.69 24.3 18.69 24.06C18.69 23.82 18.78 23.59 18.95 23.42L22.12 20.25L23.53 18.84L23.92 18.45L24.38 18L28.95 13.42C29.12 13.25 29.34 13.16 29.57 13.16C29.82 13.16 30.08 13.27 30.3 13.49C30.66 13.84 30.66 14.41 30.3 14.77L25.73 19.35L24.88 20.19Z"
          fill={color}
        />
        <path
          d="M25.95 20.42C25.83 20.53 25.68 20.59 25.53 20.59C25.47 20.59 25.41 20.58 25.36 20.57C25.26 20.54 25.18 20.49 25.1 20.42L24.88 20.19L23.92 19.23L23.53 18.84L23.31 18.62C23.24 18.55 23.19 18.46 23.16 18.37C23.09 18.17 23.14 17.93 23.31 17.77C23.47 17.61 23.7 17.56 23.9 17.63H23.92C24 17.66 24.08 17.71 24.15 17.77L24.38 18L25.73 19.35L25.95 19.57C26.03 19.64 26.07 19.73 26.1 19.82C26.16 20.02 26.11 20.26 25.95 20.42Z"
          fill={color}
        />
        <path
          d="M19.06 25.5C19.06 25.5 19.01 25.56 18.99 25.58L18.62 25.95L17.75 26.82C17.63 26.94 17.48 27 17.32 27C17.17 27 17.02 26.94 16.9 26.82C16.67 26.59 16.67 26.21 16.9 25.97L17.78 25.09L18.14 24.73C18.14 24.73 18.19 24.68 18.22 24.67C18.44 24.51 18.74 24.52 18.95 24.7C18.95 24.7 18.9533 24.7033 18.96 24.71C18.96 24.71 18.98 24.71 18.99 24.73C18.99 24.74 19.01 24.75 19.02 24.77H19.03C19.2 24.98 19.21 25.29 19.06 25.5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_5856_16">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KrContract;
