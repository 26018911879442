import React, { useCallback } from 'react';
import { MdOutlineWidgets } from 'react-icons/md';

import { Container } from './styles';
import Modality, { ICategoryData } from './Modality';
import { IModalityData } from '../Modalities';

interface ICategories {
  typeSelected?: number;
  className: string;
  modalities: IModalityData[];
  onChange?(data: IModalityData[]): void;
}

const Categories: React.FC<ICategories> = ({
  typeSelected,
  className,
  onChange,
  modalities,
}) => {
  const handleChangeModality = useCallback(
    (categories: ICategoryData[], modalityIndex: number) => {
      const newModalities = modalities.slice();
      newModalities[modalityIndex].categories = categories;
      if (onChange) {
        onChange(newModalities);
      }
    },
    [modalities, onChange]
  );

  return (
    <Container className={className}>
      <div className="col-12">
        <h2 className="h4 fw-semibold text-center mt-5 mb-4">5 - Categorias</h2>
        <p className="mb-4">
          Cadastre ao menos uma categoria para cada modalidade do seu evento
          para seguir para o próximo passo.
        </p>
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="h5 fw-normal roboto d-flex align-items-center">
            <MdOutlineWidgets color="#000" size={20} className="me-1" />
            Categorias
          </h3>
          {/* <span>
            <IoMdInformationCircleOutline size={16} color="#6C6C6C" />
          </span> */}
        </div>
        {modalities.map((modality, index) => (
          <Modality
            key={modality.input_id}
            data={modality}
            typeSelected={typeSelected}
            onChange={(categories) => handleChangeModality(categories, index)}
          />
        ))}
      </div>
    </Container>
  );
};

export default Categories;
