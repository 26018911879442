/* eslint-disable import/no-duplicates */
import React, { useCallback, useState } from 'react';
import DatePicker from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import Input from '~/components/Input';
import { Container } from './styles';
import { KrCalendar } from '../KoroIcons';

interface InputDateProps {
  name?: string;
  onChange?(date: Date | [Date, Date] | null): void;
  selected?: Date | null;
  className?: string;
  dateFormat?: string | string[];
  placeholder?: string;
  isError?: boolean;
  colorSvg?: string;
}

const InputDate: React.FC<InputDateProps> = ({
  name,
  onChange,
  selected,
  className,
  dateFormat,
  placeholder,
  isError,
  colorSvg,
}) => {
  const [value, setValue] = useState('');

  const handleChange = useCallback(
    (e) => {
      setValue(e);
      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  return (
    <>
      <Container className="bd-select d-flex align-items-center py-1 px-2">
        <DatePicker
          onChange={handleChange}
          selected={selected}
          className={`${className} ${isError ? 'error-input' : ''}`}
          dateFormat={dateFormat || 'dd/MM/yyyy'}
          locale={ptBR}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          placeholderText={placeholder}
        />
        <KrCalendar size={14} color={colorSvg ?? '#D4D4D4'} />
      </Container>
      {name && (
        <Input
          type="hidden"
          name={name}
          className="d-none"
          value={value || selected?.toISOString()}
        />
      )}
    </>
  );
};

export default InputDate;
