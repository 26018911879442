/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useCallback, useState } from 'react';
import { format } from 'date-fns';

import { Container } from './styles';
import Input from '~/components/Input';
import { IBatch } from '../Batches';
import { IModalityData } from '../Modalities';
import InputMask from '~/components/InputMask';

export interface IPricePlace {
  input_id: number;
  qtdPlaces?: number;
  expiration: Date;
}

interface IBatchError {
  batch_id: number;
  error: string;
}

export interface IBatchCategory {
  batch_id: number;
  category_id: number;
  modality_id: number;
  quantity: number;
  price: number;
}

interface IPricesPlaces {
  batches: IBatch[];
  modalities: IModalityData[];
  className: string;
  onChange?(data: IPricePlace[]): void;
}

const PricesPlaces: React.FC<IPricesPlaces> = ({
  batches,
  modalities,
  className,
  onChange,
}) => {
  const [batchesErrors, setBatchesErrors] = useState<IBatchError[]>([]);

  const handleChangeQuantity = useCallback(
    (e, category, batchIndex, modalityId) => {
      const newBatches = batches.slice();

      let total = newBatches[batchIndex].batchesCategories?.reduce(
        (previous, current) => {
          if (current.modality_id !== modalityId) {
            return previous + parseInt(current.quantity.toString(), 10);
          }
          if (
            current.category_id !== category.id &&
            current.modality_id === modalityId
          ) {
            return previous + parseInt(current.quantity.toString(), 10);
          }

          return previous;
        },
        0
      );
      total = (total || 0) + parseInt(e.target.value || '0', 10);
      const { qtdPlaces } = newBatches[batchIndex];
      if (qtdPlaces && qtdPlaces < total) {
        setBatchesErrors((oldState) => [
          ...oldState,
          {
            batch_id: newBatches[batchIndex].input_id,
            error: 'Quantidade ultrapassada',
          },
        ]);
      } else {
        setBatchesErrors((oldState) =>
          oldState.filter(
            (error) => error.batch_id !== newBatches[batchIndex].input_id
          )
        );
      }

      newBatches[batchIndex] = {
        ...newBatches[batchIndex],
        restPlaces: (newBatches[batchIndex].qtdPlaces || 0) - total,
      };

      let batchCategoryIndex = newBatches[
        batchIndex
      ].batchesCategories?.findIndex(
        (batchCategory) =>
          batchCategory.category_id === category.id &&
          batchCategory.modality_id === modalityId
      );

      if (
        typeof batchCategoryIndex !== 'undefined' &&
        batchCategoryIndex >= 0
      ) {
        const { batchesCategories } = newBatches[batchIndex];
        if (batchesCategories) {
          batchesCategories[batchCategoryIndex].quantity = e.target.value;
          newBatches[batchIndex].batchesCategories = batchesCategories;
        }
      } else {
        const { batchesCategories } = newBatches[batchIndex];
        if (batchesCategories) {
          batchesCategories.push({
            batch_id: newBatches[batchIndex].input_id,
            category_id: category.id,
            modality_id: modalityId,
            quantity: e.target.value,
            price: 0,
          });
          newBatches[batchIndex].batchesCategories = batchesCategories;
          batchCategoryIndex =
            newBatches[batchIndex].batchesCategories?.length || 0;
        } else {
          newBatches[batchIndex].batchesCategories = [
            {
              batch_id: newBatches[batchIndex].input_id,
              category_id: category.id,
              modality_id: modalityId,
              quantity: e.target.value,
              price: 0,
            },
          ];
          batchCategoryIndex = 0;
        }
      }

      if (onChange) {
        onChange(newBatches);
      }
    },
    [batches, onChange]
  );

  const handleChangePrice = useCallback(
    (e, category, batchIndex, modalityId) => {
      const newBatches = batches.slice();

      const batchCategoryIndex = newBatches[
        batchIndex
      ].batchesCategories?.findIndex(
        (batchCategory) =>
          batchCategory.category_id === category.id &&
          batchCategory.modality_id === modalityId
      );

      if (
        typeof batchCategoryIndex !== 'undefined' &&
        batchCategoryIndex >= 0
      ) {
        const { batchesCategories } = newBatches[batchIndex];
        if (batchesCategories) {
          batchesCategories[batchCategoryIndex].price = parseFloat(
            e.target.value
              .replace('R$', '')
              .replaceAll('.', '')
              .replace(',', '.')
          );
          newBatches[batchIndex].batchesCategories = batchesCategories;
        }
      } else {
        const { batchesCategories } = newBatches[batchIndex];
        if (batchesCategories) {
          batchesCategories.push({
            batch_id: newBatches[batchIndex].input_id,
            category_id: category.id,
            modality_id: modalityId,
            quantity: 0,
            price: parseFloat(
              e.target.value
                .replace('R$', '')
                .replaceAll('.', '')
                .replace(',', '.')
            ),
          });
          newBatches[batchIndex].batchesCategories = batchesCategories;
        } else {
          newBatches[batchIndex].batchesCategories = [
            {
              batch_id: newBatches[batchIndex].input_id,
              category_id: category.id,
              modality_id: modalityId,
              quantity: 0,
              price: parseFloat(
                e.target.value
                  .replace('R$', '')
                  .replaceAll('.', '')
                  .replace(',', '.')
              ),
            },
          ];
        }
      }

      if (onChange) {
        onChange(newBatches);
      }
    },
    [batches, onChange]
  );

  return (
    <Container className={className}>
      <div className="col-12">
        <h2 className="h4 fw-semibold text-center mt-5 mb-5">
          7 - Preços e vagas
        </h2>
      </div>
      {batches.map((batch, index) => (
        <div key={batch.input_id} className="col-lg-6">
          <div className="bg-category p-3 mb-3">
            <h5 className="roboto h5 fw-semibold">Lote {index + 1}</h5>
            <p className="mb-0">
              Vagas: {batch.qtdPlaces || 0} | Validade:{' '}
              {format(batch.expiration, 'dd/MM/yyyy')}
            </p>
          </div>
          {modalities.map((modality) => (
            <Fragment key={modality.id}>
              {modality.categories?.map((category) => (
                <div
                  key={category.id}
                  className="bd-lots all-lots mb-3 p-3 d-flex justify-content-between align-items-center"
                >
                  <h3 className="h6 mb-0 fw-semibold roboto d-flex align-items-center">
                    {modality.name}: {category.name}
                  </h3>
                  <div className="qty-size">
                    <span className="w-75 mx-auto roboto h6 fw-normal mb-2 d-block">
                      Qtd. de vagas
                    </span>
                    <Input
                      type="number"
                      name="qtd-places"
                      placeholder="0"
                      onChange={(e) =>
                        handleChangeQuantity(e, category, index, modality.id)
                      }
                      className={`${
                        !!batchesErrors.find(
                          (batchError) => batchError.batch_id === batch.input_id
                        )?.error && 'border-danger'
                      } w-75 mx-auto input`}
                      min={0}
                      max={
                        (batch.restPlaces || 0) +
                        (batch.batchesCategories
                          ? batch.batchesCategories[
                              batch.batchesCategories?.findIndex(
                                (batchCategory) =>
                                  batchCategory.category_id === category.id
                              )
                            ]?.quantity || 0
                          : 0)
                      }
                      onWheel={(e: any) => e.target.blur()}
                    />
                    <small className="d-block text-center text-danger">
                      {
                        batchesErrors.find(
                          (batchError) => batchError.batch_id === batch.input_id
                        )?.error
                      }
                    </small>
                  </div>
                  <div>
                    <span className="w-75 mx-auto roboto h6 fw-normal mb-2 d-block">
                      Preços
                    </span>
                    <InputMask
                      kind="money"
                      name="price"
                      placeholder="R$ 0,00"
                      onChange={(e) =>
                        handleChangePrice(e, category, index, modality.id)
                      }
                      className="w-75 mx-auto input"
                    />
                  </div>
                </div>
              ))}
            </Fragment>
          ))}
        </div>
      ))}
    </Container>
  );
};

export default PricesPlaces;
