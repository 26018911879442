/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { addHours, format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import api from '~/services/api';
import Toast from '~/utils/toast';

import { Cards, Container, Filter, Status } from './styles';
import InfiniteScroll from '~/components/InfiniteScroll';
import BtnNewEvent from '~/components/BtnNewEvent';
import ModalNewUser from '~/components/ModalNewUser';
import { IOption } from '~/components/Select';
import FiltersLists from '~/components/FiltersLists';

import imgDefault from '~/assets/icons/image-default.svg';
import { KrClose, KrEdit, KrEye, KrSearch } from '~/components/KoroIcons';

interface IItem {
  id: number;
}

interface IOrder {
  items: IItem[];
}

interface IBatch {
  id: number;
  quantity: number;
  due_date: string;
}

interface IArchiveEvent {
  archive: {
    archive_url: string;
  };
}

interface IEvent {
  id: number;
  archive_url: string;
  title: string;
  date: string;
  status: string;
  endRegistration: string;
  purchases: number;
  allTickets: number;
  slug: string;
}

interface IEventData {
  id: number;
  name: string;
  description: string;
  date: string;
  slug: string;
  archivesEvents: IArchiveEvent[];
  batches: IBatch[];
  sales_end_date: string;
  orders: IOrder[];
  status: {
    name: string;
  };
}

interface IEventResponse {
  data: IEventData[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

interface IEventType {
  id: number;
  name: string;
}

interface IStatus {
  id: number;
  name: string;
}

const Events: React.FC = () => {
  const [show, setShow] = useState(false);
  const [events, setEvents] = useState<IEvent[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);
  const [categories, setCategories] = useState<IOption[]>([]);
  const [status, setStatus] = useState<IOption[]>([]);
  const [statusSelected, setStatusSelected] = useState<string | undefined>(
    undefined
  );
  const [typeSelected, setTypeSelected] = useState<string | undefined>(
    undefined
  );
  const [dateStartSelected, setDateStartSelected] = useState<
    string | undefined
  >(undefined);
  const [dateEndSelected, setDateEndSelected] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    api.get<IStatus[]>('events-status').then((response) => {
      const data = response.data.map<IOption>((statusData) => ({
        id: statusData.id,
        value: statusData.name,
        selected: false,
      }));
      setStatus(data);
    });
  }, []);

  const handleLoadEvents = useCallback(
    async (
      pageData,
      status_id?: string,
      type_id?: string,
      dateStart?: string,
      dateEnd?: string
    ) => {
      try {
        const response = await api.get<IEventResponse>('events/organizer', {
          params: {
            page: pageData,
            category_id: type_id,
            status_id,
            startDate: dateStart ? new Date(dateStart) : undefined,
            endDate: dateEnd ? new Date(dateEnd) : undefined,
          },
        });

        const data = response.data.data.map<IEvent>((event) => {
          const allTickets = event.batches.reduce(
            (previous, current) => previous + current.quantity,
            0
          );

          const endRegistration = format(
            parseISO(event.sales_end_date || event.date),
            'dd/MM/yyyy'
          );

          const purchases = event.orders.reduce(
            (previous, current) => previous + current.items.length,
            0
          );

          return {
            id: event.id,
            archive_url:
              event.archivesEvents[0]?.archive.archive_url || imgDefault,
            title: event.name,
            date: format(addHours(parseISO(event.date), 3), 'dd/MM/yyyy'),
            status: event.status.name,
            endRegistration,
            purchases,
            allTickets,
            slug: event.slug,
          };
        });

        if (pageData === 1) {
          setEvents(data);
        } else {
          setEvents((oldState) => [...oldState, ...data]);
        }
        setTotalPages(response.data.pages);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingMore(false);
      }
    },
    []
  );

  useEffect(() => {
    handleLoadEvents(1);
  }, [handleLoadEvents]);

  useEffect(() => {
    api
      .get<IEventType[]>('events-types', { params: { isOrganizer: true } })
      .then((response) => {
        const data = response.data.map<IOption>((modality) => ({
          id: modality.id,
          value: modality.name,
          selected: false,
        }));

        setCategories(data);
      });
  }, []);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleClickDelete = useCallback((event) => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#ff6900',
      title: `Gostaria de excluir o evento ${event.title}?`,
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      confirmButtonColor: '#ff6900',
      cancelButtonColor: '#B34A00',
      reverseButtons: true,
    }).then(async (e) => {
      if (e.isConfirmed) {
        await api.delete(`events/organizer/${event.id}`);
        setEvents((oldState) =>
          oldState.filter((eventData) => eventData.id !== event.id)
        );
        Toast.fire({
          icon: 'success',
          iconColor: '#ff6900',
          title: 'Evento excluído com sucesso!',
        });
      }
    });
  }, []);

  const handleLoad = useCallback(async () => {
    if (!loadingMore) {
      setLoadingMore(true);
      if (page < totalPages) {
        await handleLoadEvents(
          page + 1,
          statusSelected !== 'todos' ? statusSelected : undefined,
          typeSelected !== 'todos' ? typeSelected : undefined,
          dateStartSelected,
          dateEndSelected
        );
        setPage(page + 1);
      } else {
        setLoadingMore(false);
      }
    }
  }, [
    loadingMore,
    page,
    totalPages,
    handleLoadEvents,
    statusSelected,
    typeSelected,
    dateStartSelected,
    dateEndSelected,
  ]);

  const handleSubmitFilters = useCallback(
    (data) => {
      setStatusSelected(data.status);
      setTypeSelected(data.sports);
      setDateStartSelected(data.dateStart);
      setDateEndSelected(data.dateEnd);
      handleLoadEvents(
        1,
        data.status !== 'todos' ? data.status : undefined,
        data.sports !== 'todos' ? data.sports : undefined,
        data.dateStart,
        data.dateEnd
      );
    },
    [handleLoadEvents]
  );

  return (
    <Container className="mt-n3 mb-n3">
      <InfiniteScroll
        id="infinite-scroll"
        onInfiniteLoad={handleLoad}
        className="infinite-scroll py-3"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="zoom col-12 px-0">
              <div className="bg-white d-flex justify-content-between align-items-center p-4">
                <h1 className="mb-0">Eventos</h1>
                <div>
                  {false && (
                    <button
                      type="button"
                      onClick={handleShow}
                      className="mb-0 me-4 h6 fw-normal text-white btn-new px-5 py-2"
                    >
                      <HiPlus size={16} color="#fff" className="me-2" />
                      Novo usuário
                    </button>
                  )}
                  <BtnNewEvent />
                </div>
              </div>
            </div>
            <Filter className="col-12 px-0 mt-3">
              <FiltersLists
                onSubmitFilters={handleSubmitFilters}
                category
                statusEvent
                date
                categories={categories}
                statusOptions={status}
              />
            </Filter>

            <div className="zoom col-12 pe-0">
              {events.map((event) => (
                <Cards className="d-flex bg-white justify-content-between align-items-center p-3 mt-3">
                  <div className="w-5">
                    <img
                      src={event.archive_url}
                      alt="Corrida"
                      className="default"
                    />
                  </div>
                  <div className="w-15">
                    <h3 className="mb-1">Nome</h3>
                    <p className="mb-0">{event.title}</p>
                  </div>
                  <div>
                    <h3 className="mb-1">Data do evento</h3>
                    <p className="mb-0">{event.date}</p>
                  </div>
                  <div className="w-10">
                    <h3 className="mb-1">Status</h3>
                    <Status
                      status={event.status}
                      className="text-center rounded-pill text-white mb-0"
                    >
                      {event.status}
                    </Status>
                  </div>
                  <div>
                    <h3 className="mb-1">Fim das inscrições</h3>
                    <p className="mb-0">{event.endRegistration}</p>
                  </div>
                  <div>
                    <h3 className="mb-1">Ingressos vendidos</h3>
                    <p className="mb-0">{event.purchases}</p>
                  </div>
                  <div>
                    <h3 className="mb-1">Qtd. de ingressos totais</h3>
                    <p className="mb-0">{event.allTickets}</p>
                  </div>
                  <div>
                    <a
                      href={`${process.env.REACT_APP_ATHLETE_URL}/eventos/${event.slug}/visualizacao`}
                      className="position-relative me-3 border-0 "
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span
                        className="tooltip-event bg-orange rounded-circle"
                        data-tooltip="Pré-visualização da página do evento (visão do atleta)"
                      >
                        <KrEye size={16} color="#fff" />
                      </span>
                    </a>
                    <Link
                      to={`${process.env.PUBLIC_URL}/eventos/${
                        event.status === 'Rascunho'
                          ? `editar-evento/${event.slug}`
                          : `${event.slug}/resumo`
                      }`}
                      className="position-relative me-3 border-0"
                    >
                      {event.status === 'Rascunho' ? (
                        <span
                          className="tooltip-event bg-orange rounded-circle"
                          data-tooltip="Editar informações do evento"
                        >
                          <KrEdit size={16} color="#fff" />
                        </span>
                      ) : (
                        <span
                          className="tooltip-event bg-orange rounded-circle"
                          data-tooltip="Ver resumo das informações do evento"
                        >
                          <KrSearch size={16} color="#fff" />
                        </span>
                      )}
                    </Link>
                    <button
                      type="button"
                      className={`${
                        event.status === 'Rascunho'
                          ? 'opacity-100'
                          : 'opacity-25 pe-none'
                      } border-0 position-relative bg-transparent`}
                      onClick={() => {
                        if (event.status === 'Rascunho') {
                          handleClickDelete(event);
                        }
                      }}
                    >
                      <span
                        className="tooltip-event bg-orange rounded-circle"
                        data-tooltip="Apagar rascunho do evento"
                      >
                        <KrClose size={16} color="#fff" />
                      </span>
                    </button>
                  </div>
                </Cards>
              ))}
            </div>
          </div>
        </div>
      </InfiniteScroll>
      <ModalNewUser show={show} onClose={() => setShow(false)} />
    </Container>
  );
};

export default Events;
