import React from 'react';
import Lottie from 'react-lottie';

import { Container } from './styles';

import koroLoading from '~/assets/animations/koro-loading.json';

interface ILoading {
  active: boolean;
  text?: string;
}

const Loading: React.FC<ILoading> = ({ active, text }) => {
  return (
    <Container active={active}>
      <div className="anim-lottie">
        <Lottie
          options={{
            animationData: koroLoading,
            autoplay: true,
            loop: true,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={150}
          width={120}
        />
      </div>
      {text && <p className="mt-3">{text}</p>}
    </Container>
  );
};

export default Loading;
