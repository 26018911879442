/* eslint-disable no-console */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { differenceInYears, format, parseISO } from 'date-fns';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';
import {
  Container,
  Filter,
  Score,
  Client,
  QrCode,
  Gender,
  Age,
} from './styles';

import MapBrazil from './MapBrazil';

import pointer from '~/assets/default/pointer-new.svg';
import scale from '~/assets/default/scale-new.svg';
import Select, { IOption } from '~/components/Select';
import { useResize } from '~/hooks/Resize';
import api from '~/services/api';
import FiltersLists from '~/components/FiltersLists';
import ExportCsv from '~/components/ExportCsv';
import { IColumn } from '~/components/Table';

interface ITicketData {
  id: number;
  user_id: number;
  code: number;
  document: string;
  phone: string;
  email: string;
  name: string;
  status: string;
  modality?: {
    id: number;
    name: string;
  };
  batchCategory: {
    id: number;
    batch: {
      event: {
        id: number;
        name: string;
        description: string;
        date: string;
        type: {
          name: string;
        };
        event: {
          date: string;
        };
        address: {
          street: string;
          number: number;
          neighborhood: string;
          city: string;
          state: string;
          complement: string;
          zip_code: string;
        };
      };
    };
    category: {
      name: string;
    };
  };
  orderItem: {
    order: {
      status: string;
      code: number;
      payment: {
        qr_code?: string;
        payment_type: string;
      };
      created_at: string;
      user: {
        name: string;
        email: string;
        additionalInformation: {
          phone: string;
        };
      };
    };
  };
  kitUser?: {
    color?: {
      name: string;
    };
    size?: {
      size: string;
    };
  };
  created_at: string;
  buyerName: string;
  buyerPhone: string;
}

interface IOrder {
  total: string;
}

interface IUser {
  id: number;
  name: string;
  email: string;
  created_at: string;
  additionalInformation: {
    document?: string;
    phone: string;
    birthdate: string;
    gender: string;
    address: {
      zip_code: string;
      street: string;
      number: number;
      neighborhood: string;
      city: string;
      state: string;
      country: string;
      complement?: string;
    };
  };
  orders: IOrder[];
}

interface ICity {
  [key: string]: number;
}

interface IStatus {
  id: number;
  name: string;
}

interface IEventType {
  id: number;
  name: string;
}

interface IEvent {
  id: number;
  name: string;
}

interface ITicket {
  id: string;
  name: string;
  document: string;
  email: string;
  event_id: number;
  event_name: string;
  event_date: string;
  modality: string | undefined;
  category: string;
  kit: string;
}

const Clients: React.FC = () => {
  const { width } = useResize();
  const [donutChart, setDonutChart] = useState(300);
  const [barChart, setBarChart] = useState(450);
  const formRef = useRef<FormHandles>(null);
  const [clientsPerStates, setClientsPerStates] = useState({
    AC: 0,
    AL: 0,
    AP: 0,
    AM: 0,
    BA: 0,
    CE: 0,
    DF: 0,
    ES: 0,
    GO: 0,
    MA: 0,
    MT: 0,
    MS: 0,
    MG: 0,
    PA: 0,
    PB: 0,
    PR: 0,
    PE: 0,
    PI: 0,
    RJ: 0,
    RN: 0,
    RS: 0,
    RO: 0,
    RR: 0,
    SC: 0,
    SP: 0,
    SE: 0,
    TO: 0,
  });
  const [clientsPerAge, setClientsPerAge] = useState({
    adolescents: 0,
    youngAdults: 0,
    youngProfessionals: 0,
    adults: 0,
    middleAgedAdults: 0,
    seniors: 0,
  });
  const [clientsPerGender, setClientsPerGender] = useState({
    male: 0,
    female: 0,
  });
  const [users, setUsers] = useState<IUser[]>([]);
  const [events, setEvents] = useState<IOption[]>([]);
  const [tickets, setTickets] = useState<ITicket[]>([]);

  useEffect(() => {
    api
      .get<IEvent[]>('events/organizer', {
        params: {
          status_id: 3,
        },
      })
      .then((response) => {
        const data = response.data.map((event) => ({
          id: event.id,
          value: event.name,
          selected: false,
        }));
        setEvents([
          {
            id: 'all',
            value: 'Todos os eventos',
            selected: true,
          },
          ...data,
        ]);
      });
  }, []);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
      },
      {
        name: 'Id do evento',
        selector: 'event_id',
      },
      {
        name: 'Nome do evento',
        selector: 'event_name',
      },
      {
        name: 'Nome',
        selector: 'name',
      },
      {
        name: 'Documento',
        selector: 'document',
      },
      {
        name: 'Telefone',
        selector: 'phone',
      },
      {
        name: 'E-mail',
        selector: 'email',
      },
      {
        name: 'Data do evento',
        selector: 'event_date',
      },
      {
        name: 'Modalidade',
        selector: 'modality',
      },
      {
        name: 'Categoria',
        selector: 'category',
      },
      {
        name: 'Kit',
        selector: 'kit',
      },

      {
        name: 'Data compra',
        selector: 'buy_date',
      },
      {
        name: 'Nome comprador',
        selector: 'buyer_name',
      },
      {
        name: 'Fone comprador',
        selector: 'buyer_phone',
      },
      {
        name: 'Email comprador',
        selector: 'buyer_email',
      },
    ],
    []
  );

  const seriesTransfer = useMemo(() => {
    return Object.values(clientsPerGender);
  }, [clientsPerGender]);

  const optionsTransfer = useMemo(() => {
    const data: ApexOptions = {
      colors: ['#ff842f', '#002F6C'],
      chart: {
        type: 'donut',
      },

      plotOptions: {
        pie: {
          expandOnClick: false,

          startAngle: 0,
          endAngle: 360,
          donut: {
            size: '65%',
            labels: {
              show: false,
            },
          },
        },
      },

      legend: {
        customLegendItems: ['item1', 'item2'],
        show: false,
      },
      dataLabels: {
        enabled: false,
      },

      stroke: {
        show: true,
        width: 5,
        lineCap: 'round',
      },

      labels: ['Homens', 'Mulheres'],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
              height: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
    return data;
  }, []);

  const seriesEntries = useMemo(() => {
    return [
      {
        name: 'Idades',
        data: Object.values(clientsPerAge),
      },
    ];
  }, [clientsPerAge]);

  const optionsEntries = useMemo(() => {
    const data: ApexOptions = {
      colors: ['#FF842F'],
      chart: {
        height: 350,
        type: 'bar',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },

      xaxis: {
        categories: [
          'Abaixo de 18 anos',
          'Entre 18 e 24',
          'Entre 25 e 34',
          'Entre 35 e 44',
          'Entre 45 e 55',
          'Acima de 55',
        ],
      },
    };
    return data;
  }, []);

  const [categories, setCategories] = useState<IOption[]>([]);
  const [status, setStatus] = useState<IOption[]>([]);
  const [eventSelected, setEventSelected] = useState<IOption | undefined>(
    undefined
  );

  useEffect(() => {
    api.get<IStatus[]>('events-status').then((response) => {
      const data = response.data.map<IOption>((statusData) => ({
        id: statusData.id,
        value: statusData.name,
        selected: false,
      }));
      setStatus(data);
    });
  }, []);

  useEffect(() => {
    api
      .get<IEventType[]>('events-types', { params: { isOrganizer: true } })
      .then((response) => {
        const data = response.data.map<IOption>((modality) => ({
          id: modality.id,
          value: modality.name,
          selected: false,
        }));

        setCategories(data);
      });
  }, []);

  useEffect(() => {
    if (width > 1200) {
      setDonutChart(190);
      setBarChart(220);
    }
    if (width > 1400) {
      setBarChart(230);
    }
    if (width > 1600) {
      setDonutChart(180);
      setBarChart(220);
    }
    if (width > 1700) {
      setBarChart(300);
      setDonutChart(250);
    }
  }, [width]);

  const handleLoadClients = useCallback(
    async (
      event?: string,
      status_id?: string,
      type_id?: string,
      dateStart?: string,
      dateEnd?: string
    ) => {
      const response = await api.get<IUser[]>(`users/organizers`, {
        params: {
          event,
          status_id,
          type_id,
          dateStart: dateStart ? new Date(dateStart) : undefined,
          dateEnd: dateEnd ? new Date(dateEnd) : undefined,
        },
      });
      setClientsPerStates({
        AC: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'AC'
              ? previous + 1
              : previous,
          0
        ),
        AL: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'AL'
              ? previous + 1
              : previous,
          0
        ),
        AP: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'AP'
              ? previous + 1
              : previous,
          0
        ),
        AM: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'AM'
              ? previous + 1
              : previous,
          0
        ),
        BA: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'BA'
              ? previous + 1
              : previous,
          0
        ),
        CE: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'CE'
              ? previous + 1
              : previous,
          0
        ),
        DF: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'DF'
              ? previous + 1
              : previous,
          0
        ),
        ES: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'ES'
              ? previous + 1
              : previous,
          0
        ),
        GO: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'GO'
              ? previous + 1
              : previous,
          0
        ),
        MA: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'MA'
              ? previous + 1
              : previous,
          0
        ),
        MT: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'MT'
              ? previous + 1
              : previous,
          0
        ),
        MS: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'MS'
              ? previous + 1
              : previous,
          0
        ),
        MG: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'MG'
              ? previous + 1
              : previous,
          0
        ),
        PA: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'PA'
              ? previous + 1
              : previous,
          0
        ),
        PB: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'PB'
              ? previous + 1
              : previous,
          0
        ),
        PR: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'PR'
              ? previous + 1
              : previous,
          0
        ),
        PE: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'PE'
              ? previous + 1
              : previous,
          0
        ),
        PI: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'PI'
              ? previous + 1
              : previous,
          0
        ),
        RJ: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'RJ'
              ? previous + 1
              : previous,
          0
        ),
        RN: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'RN'
              ? previous + 1
              : previous,
          0
        ),
        RS: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'RS'
              ? previous + 1
              : previous,
          0
        ),
        RO: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'RO'
              ? previous + 1
              : previous,
          0
        ),
        RR: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'RR'
              ? previous + 1
              : previous,
          0
        ),
        SC: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'SC'
              ? previous + 1
              : previous,
          0
        ),
        SP: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'SP'
              ? previous + 1
              : previous,
          0
        ),
        SE: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'SE'
              ? previous + 1
              : previous,
          0
        ),
        TO: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.address?.state === 'TO'
              ? previous + 1
              : previous,
          0
        ),
      });
      setClientsPerAge({
        adolescents: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.birthdate &&
            differenceInYears(
              new Date(),
              parseISO(current.additionalInformation.birthdate)
            ) < 18
              ? previous + 1
              : previous,
          0
        ),
        youngAdults: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.birthdate &&
            differenceInYears(
              new Date(),
              parseISO(current.additionalInformation.birthdate)
            ) >= 18 &&
            differenceInYears(
              new Date(),
              parseISO(current.additionalInformation.birthdate)
            ) <= 24
              ? previous + 1
              : previous,
          0
        ),
        youngProfessionals: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.birthdate &&
            differenceInYears(
              new Date(),
              parseISO(current.additionalInformation.birthdate)
            ) >= 25 &&
            differenceInYears(
              new Date(),
              parseISO(current.additionalInformation.birthdate)
            ) <= 34
              ? previous + 1
              : previous,
          0
        ),
        adults: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.birthdate &&
            differenceInYears(
              new Date(),
              parseISO(current.additionalInformation.birthdate)
            ) >= 35 &&
            differenceInYears(
              new Date(),
              parseISO(current.additionalInformation.birthdate)
            ) <= 44
              ? previous + 1
              : previous,
          0
        ),
        middleAgedAdults: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.birthdate &&
            differenceInYears(
              new Date(),
              parseISO(current.additionalInformation.birthdate)
            ) >= 45 &&
            differenceInYears(
              new Date(),
              parseISO(current.additionalInformation.birthdate)
            ) <= 55
              ? previous + 1
              : previous,
          0
        ),
        seniors: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.birthdate &&
            differenceInYears(
              new Date(),
              parseISO(current.additionalInformation.birthdate)
            ) > 55
              ? previous + 1
              : previous,
          0
        ),
      });
      setClientsPerGender({
        male: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.gender &&
            current.additionalInformation.gender === 'M'
              ? previous + 1
              : previous,
          0
        ),
        female: response.data.reduce(
          (previous, current) =>
            current.additionalInformation.gender &&
            current.additionalInformation.gender === 'F'
              ? previous + 1
              : previous,
          0
        ),
      });
      setUsers(response.data);
    },
    []
  );

  useEffect(() => {
    handleLoadClients();
  }, [handleLoadClients]);

  const handleSubmitFilters = useCallback(
    (data) => {
      handleLoadClients(
        data.event,
        data.status !== 'todos' ? data.status : undefined,
        data.sports !== 'todos' ? data.sports : undefined,
        data.dateStart,
        data.dateEnd
      );
    },
    [handleLoadClients]
  );

  const ranking = useMemo(() => {
    const frequency = users.reduce((previous, current) => {
      const newResult = previous;
      const city = current.additionalInformation.address?.city;
      if (city) {
        if (newResult[city]) {
          newResult[city] += 1;
        } else {
          newResult[city] = 1;
        }
      }
      return newResult;
    }, {} as ICity);

    // Passo 2: Ordenar as cidades pela frequência
    const orderCities = Object.keys(frequency).sort(
      (a, b) => frequency[b] - frequency[a]
    );

    // Passo 3: Formatar o resultado em um ranking numerado
    const data = orderCities
      .slice(0, 5)
      .map((city, index) => `${index + 1}º - ${city}`);

    return data;
  }, [users]);

  const handleSelectEvent = useCallback(async (option) => {
    if (option.id !== 'all') {
      setEventSelected(option);
    } else {
      setEventSelected(undefined);
    }
  }, []);

  const handleLoadTickets = useCallback(async () => {
    let response: AxiosResponse<ITicketData[]>;
    if (eventSelected) {
      response = await api.get(`tickets/events/${eventSelected.id}`, {
        params: {
          status: ['Usado', 'Disponível'],
        },
      });
      console.log(response.data);
    } else {
      response = await api.get(`tickets/events/organizer`, {
        params: {
          status: ['Usado', 'Disponível'],
        },
      });
      console.log(response.data);
    }

    const data = response.data.map((ticket) => {
      return {
        id: `#${ticket.code}`,
        event_id: ticket.batchCategory.batch.event.id,
        event_name: ticket.batchCategory.batch.event.name,
        name: ticket.name || '-',
        document: ticket.document || '-',
        phone: ticket.phone || '-',
        email: ticket.email || '-',
        event_date: format(
          parseISO(ticket.batchCategory.batch.event.date),
          'dd/MM/yyyy'
        ),
        modality: ticket.modality?.name,
        category: ticket.batchCategory.category.name,
        kit: ticket.kitUser
          ? `${
              ticket.kitUser.color ? `Cor: ${ticket.kitUser.color.name}, ` : ''
            }${
              ticket.kitUser.size ? `Tamanho: ${ticket.kitUser.size.size}` : ''
            }`
          : 'Não Possui ou não selecionado',
        buy_date: format(parseISO(ticket.created_at), 'dd/MM/yyyy'),
        buyer_name: ticket.orderItem.order.user.name,
        buyer_phone: ticket.orderItem.order.user.additionalInformation.phone,
        buyer_email: ticket.orderItem.order.user.email,
      };
    });

    setTickets(data);
    console.log(data);
  }, [eventSelected]);
  //
  useEffect(() => {
    handleLoadTickets();
  }, [handleLoadTickets]);

  const handleClickExportCsv = useCallback(async () => {
    console.log(tickets);
    return tickets;
  }, [tickets]);

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="zoom col-12 px-0">
            <div className="bg-white p-4">
              <h1 className="mb-0">Clientes</h1>
            </div>
          </div>
          <Filter className="col-12 px-0 mt-3">
            <FiltersLists
              onSubmitFilters={handleSubmitFilters}
              event
              category
              categories={categories}
              statusEvent
              statusOptions={status}
              date
            />
          </Filter>

          <div className="col-lg-12 ps-lg-0">
            <div className="row">
              <Gender className="col-lg-4 pe-0 mt-3">
                <div className="bg-white p-3">
                  <h2 className="zoom">Gênero</h2>
                  <hr className="mt-2 mb-4" />
                  <div className="d-flex justify-content-center">
                    <ReactApexChart
                      options={optionsTransfer}
                      series={seriesTransfer}
                      type="donut"
                      width={donutChart}
                      height={donutChart}
                    />
                  </div>
                  <p className="zoom mt-3">
                    <span className="bg-men py-1 px-2">Homens</span> -{' '}
                    {seriesTransfer[0] + seriesTransfer[1] === 0
                      ? 0
                      : Math.round(
                          (seriesTransfer[0] /
                            (seriesTransfer[0] + seriesTransfer[1])) *
                            100
                        )}
                    %
                  </p>
                  <p className="zoom mb-0">
                    <span className="bg-woman py-1 px-2">Mulheres</span> -{' '}
                    {seriesTransfer[0] + seriesTransfer[1] === 0
                      ? 0
                      : Math.round(
                          (seriesTransfer[1] /
                            (seriesTransfer[0] + seriesTransfer[1])) *
                            100
                        )}
                    %
                  </p>
                </div>
              </Gender>
              <Age className="col-lg-8 pe-0 mt-3">
                <div className="bg-white p-3">
                  <h2 className="zoom">Idades</h2>
                  <hr className="mt-2 mb-2" />
                  <div>
                    <ReactApexChart
                      options={optionsEntries}
                      series={seriesEntries}
                      type="bar"
                      height={barChart}
                    />
                  </div>
                </div>
              </Age>
              <div className="col-lg-5 pe-0 mt-3">
                <div className="zoom bg-white p-3">
                  <h2>Clientes por estado</h2>
                  <hr className="mt-2 mb-4" />
                  <MapBrazil
                    clientsAcre={clientsPerStates.AC}
                    clientsAlagoas={clientsPerStates.AL}
                    clientsAmapa={clientsPerStates.AP}
                    clientsAmazonas={clientsPerStates.AM}
                    clientsBahia={clientsPerStates.BA}
                    clientsCeara={clientsPerStates.CE}
                    clientsDistritoFederal={clientsPerStates.DF}
                    clientsEspiritoSanto={clientsPerStates.ES}
                    clientsGoias={clientsPerStates.GO}
                    clientsMaranhao={clientsPerStates.MA}
                    clientsMatoGrosso={clientsPerStates.MT}
                    clientsMatoGrossoSul={clientsPerStates.MS}
                    clientsMinasGerais={clientsPerStates.MG}
                    clientsPara={clientsPerStates.PA}
                    clientsParaiba={clientsPerStates.PB}
                    clientsParana={clientsPerStates.PR}
                    clientsPernambuco={clientsPerStates.PE}
                    clientsPiaui={clientsPerStates.PI}
                    clientsRioJaneiro={clientsPerStates.RJ}
                    clientsRioGrandeNorte={clientsPerStates.RN}
                    clientsRioGrandeSul={clientsPerStates.RS}
                    clientsRondonia={clientsPerStates.RO}
                    clientsRoraima={clientsPerStates.RR}
                    clientsSantaCatarina={clientsPerStates.SC}
                    clientsSaoPaulo={clientsPerStates.SP}
                    clientsSergipe={clientsPerStates.SE}
                    clientsTocantins={clientsPerStates.TO}
                  />
                </div>
              </div>
              <Score rotate={0} className="col-lg pe-0 mt-3">
                <div className="zoom bg-white p-3">
                  <h2>Score do cliente</h2>
                  <hr className="mt-2 mb-4" />
                  <p>
                    O score é uma pontuação interna que avalia os usuários,
                    desenvolvida por nós da Koro. O score vai de 0 a 1000 e no
                    sistema, damos uma medalha para o usuário dependendo do seu
                    score.
                  </p>
                  <p>
                    Como organizador você poderá ver o número real do score do
                    usuário.
                  </p>

                  <div className="text-center position-relative mb-3">
                    <img src={scale} alt="escala" className="scale" />
                    <div className="divPointer">
                      <img src={pointer} alt="Ponteiro" className="pointer" />
                    </div>
                  </div>

                  <p>O score médio dos seus clientes é:</p>
                  <span className="text-center d-block mb-3">750</span>
                  <p>
                    Na média, os perfis tem score de 650 pontos na plataforma.
                  </p>
                  <p>
                    Caso queira um relatório completo dos seus clientes, clique
                    abaixo para exportar uma tabela .csv
                  </p>
                  <Link
                    to={`${process.env.PUBLIC_URL}/listagens/usuarios`}
                    className="w-100 d-block text-center py-2 btn-orange h6 fw-normal text-white border-0"
                  >
                    Visualizar Clientes
                  </Link>
                </div>
              </Score>
              <div className="col-lg">
                <div className="row">
                  <Client className="col-12 pe-0 mt-3">
                    <div className="zoom bg-white h-100 p-3">
                      <h2>Clientes por cidade </h2>
                      <hr className="mt-2 mb-4" />
                      <div className="position">
                        {ranking.map((item) => (
                          <p className="mb-1" key={item}>
                            {item}
                          </p>
                        ))}
                      </div>
                    </div>
                  </Client>
                  <QrCode className="col-12 pe-0 mt-3">
                    <div className="zoom bg-white h-100 p-3">
                      <h2>QR Code</h2>
                      <hr className="mt-2 mb-4" />
                      <p>
                        Obtenha uma lista de QR Codes com os ingressos e dados
                        dos seus clientes para validar.
                      </p>
                      <p>Escolha um dos seus eventos para começar</p>
                      <Form
                        ref={formRef}
                        onSubmit={() => console.log('submit')}
                      >
                        <Select
                          name="event"
                          className="mb-4"
                          options={events}
                          onChange={handleSelectEvent}
                        />
                        <ExportCsv
                          columns={columns}
                          data={tickets}
                          className="w-100"
                          fileName="Dados_dos_ingressos"
                          onClick={handleClickExportCsv}
                        />
                      </Form>
                    </div>
                  </QrCode>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Clients;
