/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

import { FiArrowUpRight } from 'react-icons/fi';

import { Link } from 'react-router-dom';
import { format, parseISO, startOfDay, subDays } from 'date-fns';
import { Form } from '@unform/web';
import { LuMinimize2 } from 'react-icons/lu';
import {
  Container,
  Entries,
  Filter,
  MyLots,
  NextEvent,
  Ticket,
} from './styles';

import Select, { IOption } from '~/components/Select';
import BtnNewEvent from '~/components/BtnNewEvent';
import Table, { IColumn } from '~/components/Table';
import { useResize } from '~/hooks/Resize';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import imgDefault from '~/assets/icons/image-default.svg';
import FiltersLists from '~/components/FiltersLists';
import {
  KrAverageTicket,
  KrEntryTarget,
  KrExpand,
  KrInvoice,
  KrSavings,
  KrTargetAverageTicket,
  KrTargetEnrolled,
  KrTargetRevenue,
  KrTargetRevenueBars,
} from '~/components/KoroIcons';

interface IItem {
  id: number;
}

interface IOrder {
  id: number;
  total: string;
  status: string;
  created_at: string;
  items: IItem[];
  event: {
    name: string;
    date: string;
    user: {
      name: string;
    };
  };
  payment: {
    organizer: string;
    payment_type: string;
    installment: number;
  };
}

interface INextEvent {
  name: string;
  code: string;
  date: string;
  archive_url: string;
  ticketsSold: number;
  available: number;
  totalIncome: string;
  complaints: number;
  slug: string;
}

interface IItem {
  id: number;
}

interface IOrder {
  items: IItem[];
}

interface IBatch {
  id: number;
  name: string;
  quantity: number;
  due_date: string;
  event: IEvent;
}

interface IArchiveEvent {
  archive: {
    archive_url: string;
  };
}

interface IEvent {
  id: number;
  code: string;
  name: string;
  description: string;
  date: string;
  slug: string;
  archivesEvents: IArchiveEvent[];
  batches: IBatch[];
  orders: IOrder[];
}

interface ISaleDay {
  day: string;
  value: number;
}

interface IBatchResponse {
  data: IBatch[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

interface IBatchData {
  id: number;
  event: string;
  lot: number;
  qtd: number;
  EndDate: string;
  value: string;
  sold: number;
  next_lot: number;
}

interface IEvent {
  id: number;
  name: string;
  slug: string;
}

interface IEventType {
  id: number;
  name: string;
}

interface IModality {
  id: number;
  name: string;
}

interface IFilterData {
  event: string;
  sports: string;
  modality: string;
  dateStart?: string;
  dateEnd?: string;
}

interface ITargetsResponse {
  registrations: string;
  revenue: string;
  avarage_ticket: string;
}

const Dashboard: React.FC = () => {
  const { width } = useResize();
  const [lineChart, setLineChart] = useState(450);
  const [metrics, setMetrics] = useState({
    ticketsSold: 0,
    revenue: 0,
    averageTicket: 0,
  });
  const [metricsTarget, setMetricsTarget] = useState({
    registrations: 0,
    revenue: 0,
    averageTicket: 0,
  });
  const [salesDay, setSalesDay] = useState<ISaleDay[]>([]);
  const [nextEvent, setNextEvent] = useState({} as INextEvent);
  const [myBatches, setMyBatches] = useState<IBatchData[]>([]);
  const [tableData, setTableData] = useState({
    from: 0,
    to: 0,
    total: 0,
    totalPage: 0,
  });
  const [pageSelected, setPageSelected] = useState(1);
  const [categories, setCategories] = useState<IOption[]>([]);
  const [modalities, setModalities] = useState<IOption[]>([]);
  const [typeId, setTypeId] = useState<number | undefined>(undefined);

  const [idDate, setIdDate] = useState<number>(1);

  const [expand, setExpand] = useState(false);

  const lastSales = useMemo(
    () => [
      { id: 1, value: 'Últimos 7 dias', selected: true },
      { id: 2, value: 'Últimos 30 dias', selected: false },
      { id: 3, value: 'Últimos 60 dias', selected: false },
    ],
    []
  );

  useEffect(() => {
    api
      .get<IModality[]>('modalities', {
        params: {
          type_id: typeId,
        },
      })
      .then((response) => {
        const data = response.data.reduce((previous, current) => {
          const modality = previous.find(
            (modalityData) => modalityData.value === current.name
          );
          if (modality) {
            return previous;
          }
          return [
            ...previous,
            {
              id: current.name,
              value: current.name,
              selected: false,
            },
          ];
        }, [] as IOption[]);
        setModalities(data);
      });
  }, [typeId]);

  const handleLoadOrders = useCallback(
    async (
      event?: string,
      type_id?: string,
      modality?: string,
      dateStart?: string,
      dateEnd?: string
    ) => {
      const response = await api.get<IOrder[]>('orders/organizers', {
        params: {
          event,
          type_id,
          modality,
          dateStart: dateStart ? new Date(dateStart) : undefined,
          dateEnd: dateEnd ? new Date(dateEnd) : undefined,
        },
      });
      const ticketsSold = response.data.reduce(
        (previous, current) => previous + current.items.length,
        0
      );
      const revenue = response.data.reduce(
        (previous, current) => previous + parseFloat(current.payment.organizer),
        0
      );
      const data = response.data.reduce((previous, item) => {
        const oldSaleDay = previous;
        const dayData = startOfDay(parseISO(item.created_at));
        const day = format(dayData, 'dd/MM');
        const saleDayIndex = previous.findIndex(
          (saleDay) => saleDay.day === day
        );

        if (saleDayIndex >= 0) {
          oldSaleDay[saleDayIndex].value += item.items.length;
        } else {
          oldSaleDay.push({
            day,
            value: item.items.length,
          });
        }

        return oldSaleDay;
      }, [] as ISaleDay[]);
      const lastSalesGraph = [];
      if (idDate === 1) {
        for (let i = 0; i < 7; i += 1) {
          const dayData = subDays(new Date(), i);
          const day = format(dayData, 'dd/MM');

          const daySelected = data.find((saleDay) => saleDay.day === day);

          lastSalesGraph.push({ day, value: daySelected?.value || 0 });
        }

        setSalesDay(lastSalesGraph.reverse());
        setMetrics({
          ticketsSold,
          revenue,
          averageTicket: revenue / ticketsSold,
        });
      }
      if (idDate === 2) {
        for (let i = 0; i < 30; i += 1) {
          const dayData = subDays(new Date(), i);
          const day = format(dayData, 'dd/MM');

          const daySelected = data.find((saleDay) => saleDay.day === day);

          lastSalesGraph.push({ day, value: daySelected?.value || 0 });
        }

        setSalesDay(lastSalesGraph.reverse());
        setMetrics({
          ticketsSold,
          revenue,
          averageTicket: revenue / ticketsSold,
        });
      }
      if (idDate === 3) {
        for (let i = 0; i < 60; i += 1) {
          const dayData = subDays(new Date(), i);
          const day = format(dayData, 'dd/MM');

          const daySelected = data.find((saleDay) => saleDay.day === day);

          lastSalesGraph.push({ day, value: daySelected?.value || 0 });
        }

        setSalesDay(lastSalesGraph.reverse());
        setMetrics({
          ticketsSold,
          revenue,
          averageTicket: revenue / ticketsSold,
        });
      }
    },
    [idDate]
  );

  useEffect(() => {
    handleLoadOrders();
  }, [handleLoadOrders]);

  useEffect(() => {
    api.get<IEvent>('events/organizer/next').then((response) => {
      const ticketsSold = response.data.orders.reduce(
        (previous, current) => previous + current.items.length,
        0
      );
      const quantity = response.data.batches.reduce(
        (previous, current) => previous + current.quantity,
        0
      );
      const total = response.data.orders.reduce(
        (previous, current) => previous + parseFloat(current.payment.organizer),
        0
      );
      setNextEvent({
        name: response.data.name,
        code: response.data.code,
        archive_url:
          response.data.archivesEvents[0]?.archive.archive_url || imgDefault,
        ticketsSold,
        date: response.data.date,
        available: quantity - ticketsSold,
        totalIncome: formatPrice(total),
        complaints: 0,
        slug: response.data.slug,
      });
    });
  }, []);

  const handleLoadTargets = useCallback(
    async (
      event?: string,
      type_id?: string,
      modality?: string,
      dateStart?: string,
      dateEnd?: string
    ) => {
      const response = await api.get<ITargetsResponse>(
        'events/organizer/targets',
        {
          params: {
            event,
            type_id,
            modality,
            dateStart: dateStart ? new Date(dateStart) : undefined,
            dateEnd: dateEnd ? new Date(dateEnd) : undefined,
          },
        }
      );
      setMetricsTarget({
        revenue: parseFloat(response.data.revenue),
        registrations: parseFloat(response.data.registrations),
        averageTicket: parseFloat(response.data.avarage_ticket),
      });
    },
    []
  );

  useEffect(() => {
    handleLoadTargets();
  }, [handleLoadTargets]);

  const handleLoadBatches = useCallback(
    async (
      pageData,
      event?: string,
      type_id?: string,
      modality?: string,
      dateStart?: string,
      dateEnd?: string
    ) => {
      const response = await api.get<IBatchResponse>('batches/organizer', {
        params: {
          page: pageData,
          event,
          type_id,
          modality,
          dateStart: dateStart ? new Date(dateStart) : undefined,
          dateEnd: dateEnd ? new Date(dateEnd) : undefined,
        },
      });

      const data = response.data.data.map<IBatchData>((batch) => {
        const sold = batch.event.orders.reduce(
          (previous, current) => previous + current.items.length,
          0
        );

        return {
          id: batch.id,
          event: batch.event.name,
          lot: parseInt(batch.name.replace(/\D/g, ''), 10),
          sold,
          qtd: batch.quantity,
          EndDate: format(parseISO(batch.due_date), 'dd/MM/yyyy'),
          value: formatPrice(1000),

          next_lot: batch.quantity - sold,
        };
      });

      setMyBatches(data);
      setTableData({
        from: response.data.from,
        to: response.data.to,
        total: response.data.total,
        totalPage: response.data.pages,
      });
    },
    []
  );

  useEffect(() => {
    handleLoadBatches(1);
  }, [handleLoadBatches]);

  const ticketTarget = useMemo(
    () =>
      parseFloat(
        ((metrics.ticketsSold * 100) / metricsTarget.registrations).toFixed(2)
      ).toLocaleString('pt-BR'),
    [metrics.ticketsSold, metricsTarget.registrations]
  );

  const revenueTarget = useMemo(
    () =>
      parseFloat(
        ((metrics.revenue * 100) / metricsTarget.revenue).toFixed(2)
      ).toLocaleString('pt-BR'),
    [metrics.revenue, metricsTarget.revenue]
  );
  //
  const seriesEntries = useMemo(
    () => [
      {
        name: 'Vendas',
        data: salesDay.map((saleDay) => saleDay.value),
      },
    ],
    [salesDay]
  );

  const optionsEntries = useMemo(() => {
    const data: ApexOptions = {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
        colors: ['#00A3E0'],
      },

      xaxis: {
        categories: salesDay.map((saleDay) => saleDay.day),
      },
    };

    return data;
  }, [salesDay]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: 'Evento',
        selector: 'event',
        sortable: false,
      },
      {
        name: 'Lote',
        selector: 'lot',
        sortable: false,
      },
      {
        name: 'Qtd',
        selector: 'qtd',
        sortable: false,
      },
      {
        name: 'Data fim',
        selector: 'EndDate',
        sortable: false,
      },
      // {
      //   name: 'Valor',
      //   selector: 'value',
      //   sortable: false,
      // },
      {
        name: 'Vendidos',
        selector: 'sold',
        sortable: false,
      },

      {
        name: 'Restam',
        selector: 'next_lot',
        sortable: false,
      },
    ],
    []
  );

  useEffect(() => {
    if (width > 1200) {
      setLineChart(357);
    }
    if (width > 1400) {
      setLineChart(400);
    }
    if (width > 1600) {
      setLineChart(400);
    }
    if (width > 1700) {
      setLineChart(440);
    }
  }, [width]);

  const handleChangePage = useCallback(
    (page) => {
      if (page <= tableData.totalPage) {
        handleLoadBatches(page);
        setPageSelected(page);
      }
    },
    [handleLoadBatches, tableData.totalPage]
  );

  useEffect(() => {
    api
      .get<IEventType[]>('events-types', { params: { isOrganizer: true } })
      .then((response) => {
        const data = response.data.map<IOption>((modality) => ({
          id: modality.id,
          value: modality.name,
          selected: false,
        }));

        setCategories(data);
      });
  }, []);

  const handleSubmitFilters = useCallback(
    (data: IFilterData) => {
      if (data.sports !== 'todos') {
        setTypeId(parseInt(data.sports, 10));
      }

      handleLoadOrders(
        data.event,
        data.sports !== 'todos' ? data.sports : undefined,
        data.modality !== 'todas' ? data.modality : undefined,
        data.dateStart,
        data.dateEnd
      );
      handleLoadBatches(
        1,
        data.event,
        data.sports !== 'todos' ? data.sports : undefined,
        data.modality !== 'todas' ? data.modality : undefined,
        data.dateStart,
        data.dateEnd
      );
      handleLoadTargets(
        data.event,
        data.sports !== 'todos' ? data.sports : undefined,
        data.modality !== 'todas' ? data.modality : undefined,
        data.dateStart,
        data.dateEnd
      );
    },
    [handleLoadBatches, handleLoadOrders, handleLoadTargets]
  );

  return (
    <Container className="pb-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 px-0">
            <div className="zoom bg-white d-flex justify-content-between align-items-center p-4">
              <h1 className="mb-0">Dashboard</h1>
              <BtnNewEvent />
            </div>
          </div>
          <Filter className="col-12 px-0 mt-3">
            <FiltersLists
              onSubmitFilters={handleSubmitFilters}
              event
              category
              categories={categories}
              modality
              modalities={modalities}
              date
            />
          </Filter>
          <Ticket className={`${expand ? 'd-none' : 'col-lg-6'}`}>
            <div className="row">
              <div className="col-6 px-0 pe-3 mt-3">
                <div className="zoom d-flex aligm-items-center bg-color-blue">
                  <div className="bg-icon p-4">
                    <KrInvoice color="#fff" size={32} />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h3 className="h6 fw-normal text-white">
                      Ingressos vendidos
                    </h3>
                    <p className="h3 text-white mb-0">
                      {Number.isNaN(metrics.averageTicket)
                        ? 0
                        : metrics.ticketsSold.toLocaleString('pt-BR')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 px-0 pe-3 mt-3">
                <div className="zoom d-flex aligm-items-center bg-color-blue">
                  <div className="bg-icon p-4">
                    <KrSavings color="#fff" size={32} />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h3 className="h6 fw-normal text-white">Receita</h3>
                    <p className="h3 text-white mb-0">
                      {formatPrice(
                        Number.isNaN(metrics.revenue) ? 0 : metrics.revenue
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 px-0 pe-3 mt-3">
                <div className="zoom d-flex aligm-items-center bg-color-blue">
                  <div className="bg-icon p-4">
                    <KrEntryTarget color="#fff" size={32} />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h3 className="h6 fw-normal text-white">
                      % Meta de ingressos
                    </h3>
                    <p className="h3 text-white mb-0">
                      {Number.isNaN(parseFloat(ticketTarget))
                        ? '0%'
                        : `${ticketTarget}%`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 px-0 pe-3 mt-3">
                <div className="zoom d-flex aligm-items-center bg-color-blue">
                  <div className="bg-icon p-4">
                    <KrTargetRevenueBars color="#fff" size={32} />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h3 className="h6 fw-normal text-white">Meta de receita</h3>
                    <p className="h3 text-white mb-0">
                      {formatPrice(
                        Number.isNaN(metricsTarget.revenue)
                          ? 0
                          : metricsTarget.revenue
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 px-0 pe-3 mt-3">
                <div className="zoom d-flex aligm-items-center bg-color-orange">
                  <div className="bg-icon p-4">
                    <KrTargetEnrolled color="#fff" size={32} />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h3 className="h6 fw-normal text-white">
                      Meta de inscrições
                    </h3>
                    <p className="h3 text-white mb-0">
                      {Number.isNaN(metricsTarget.registrations)
                        ? '0%'
                        : metricsTarget.registrations.toLocaleString('pt-BR')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 px-0 pe-3 mt-3">
                <div className="zoom d-flex aligm-items-center bg-color-orange">
                  <div className="bg-icon p-4">
                    <KrTargetRevenue color="#fff" size={32} />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h3 className="h6 fw-normal text-white">
                      % Meta de receita
                    </h3>
                    <p className="h3 text-white mb-0">
                      {Number.isNaN(parseFloat(revenueTarget))
                        ? '0%'
                        : `${revenueTarget}%`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 px-0 pe-3 mt-3">
                <div className="zoom d-flex aligm-items-center bg-color-orange">
                  <div className="bg-icon p-4">
                    <KrAverageTicket color="#fff" size={32} />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h3 className="h6 fw-normal text-white">Ticket médio</h3>
                    <p className="h3 text-white mb-0">
                      {formatPrice(
                        Number.isNaN(metrics.averageTicket)
                          ? 0
                          : metrics.averageTicket
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-6 px-0 pe-3 mt-3">
                <div className="zoom d-flex aligm-items-center bg-color-orange">
                  <div className="bg-icon p-4">
                    <KrTargetAverageTicket
                      secondaryColor="#FFA363"
                      color="#fff"
                      size={32}
                    />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <h3 className="h6 fw-normal text-white">
                      Meta de Ticket médio
                    </h3>
                    <p className="h3 text-white mb-0">
                      {formatPrice(
                        Number.isNaN(metricsTarget.averageTicket)
                          ? 0
                          : metricsTarget.averageTicket
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Ticket>
          <div className={`${expand ? 'col-lg-12' : 'col-lg-6'}`}>
            <div className="row">
              <Entries className="col-12 bg-white mt-3">
                <Form
                  onSubmit={() => {
                    // console.log('ok')
                  }}
                >
                  <div className="zoom px-4 py-3 d-flex align-items-center justify-content-between">
                    <h2 className="h3 fw-semibold mb-0">Vendas por dia</h2>{' '}
                    <div className="d-flex w-50 justify-content-end">
                      <Select
                        name="lastSales"
                        className="sold-input"
                        options={lastSales}
                        onChange={(e: any) => {
                          setIdDate(e.id);
                          handleLoadOrders();
                        }}
                      />
                      <div className="d-flex align-items-center ms-4 mt-0">
                        <button
                          type="button"
                          onClick={() => setExpand(!expand)}
                          className="btn-full bg-transparent"
                        >
                          {expand ? (
                            <LuMinimize2 size={18} color="#B0B0B0" />
                          ) : (
                            <KrExpand size={18} color="#B0B0B0" />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ReactApexChart
                      options={optionsEntries}
                      series={seriesEntries}
                      type="line"
                      height={lineChart}
                    />
                  </div>
                </Form>
              </Entries>
            </div>
          </div>
          <NextEvent className="col-lg-4 mt-3 ps-0 pe-3">
            <div className="zoom bg-white p-3">
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="h4 fw-semibold mb-0">Próximo evento</h2>
                <Link to={`${process.env.PUBLIC_URL}/eventos`}>
                  <FiArrowUpRight size={24} color="#8c8c8c" />
                </Link>
              </div>
              {Object.keys(nextEvent).length > 0 && (
                <>
                  <div className="text-center mt-4 border-bottom">
                    <img src={nextEvent.archive_url} alt="usuario" />
                    <h3 className="h6 fw-normal mt-3 mb-0">{nextEvent.name}</h3>
                    <p>{format(parseISO(nextEvent.date), 'dd/MM/yyyy')}</p>
                  </div>
                  <div className="d-flex mt-4 ">
                    <div className="w-50">
                      <p className="mb-0">Ingressos vendidos</p>
                      <span className="h5 fw-normal">
                        {nextEvent.ticketsSold}
                      </span>

                      <p className="mb-0 mt-3">Renda total</p>
                      <span className="h5 fw-normal">
                        {nextEvent.totalIncome}
                      </span>
                    </div>
                    <div className="w-50">
                      <p className="mb-0">Disponíveis</p>
                      <span className="h5 fw-normal">
                        {nextEvent.available}
                      </span>

                      <p className="mb-0 mt-3">Reclamações</p>
                      <span className="h5 fw-normal">
                        {nextEvent.complaints || 'Sem reclamações'}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </NextEvent>
          <MyLots className="col-lg-8 px-0 mt-3">
            <div className="zoom bg-white p-3">
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="h4 fw-semibold mb-0">Meus Lotes</h2>
                {/* <Link
                  to={`${process.env.PUBLIC_URL}/eventos`}
                  className="see-all"
                >
                  Ver todos
                </Link> */}
              </div>
              <hr />

              <div className="">
                <Table
                  className="table p-0 mb-0"
                  columns={columns}
                  data={myBatches}
                  totalData={tableData.total}
                  pagination
                  selectedPage={pageSelected}
                  onChangePage={handleChangePage}
                />
              </div>
            </div>
          </MyLots>
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
