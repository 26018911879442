import React, { useCallback, useEffect, useState } from 'react';
import { MdOutlineWidgets } from 'react-icons/md';
import { HiPlus } from 'react-icons/hi';

import api from '~/services/api';

import { Container } from './styles';
import { IOption } from '~/components/Select';
import { ICategoryData } from '../Categories/Modality';
import Modality from './Modality';

interface IModality {
  id: number;
  name: string;
}

export interface IModalityData {
  input_id: number;
  id?: number;
  name?: string;
  categories?: ICategoryData[];
}

interface IModalities {
  typeSelected?: number;
  className: string;
  onChange?(data: IModalityData[]): void;
  initialData?: IModalityData[];
}

const Modalities: React.FC<IModalities> = ({
  typeSelected,
  className,
  onChange,
  initialData,
}) => {
  const [modalities, setModalities] = useState<IOption[]>([]);
  const [modalitiesData, setModalitiesData] = useState<IModalityData[]>([
    {
      input_id: new Date().getTime(),
    },
  ]);

  useEffect(() => {
    if (initialData) {
      setModalitiesData(initialData);
    }
  }, [initialData]);

  const handleLoadModalities = useCallback(
    async (search = '') => {
      const response = await api.get<IModality[]>('modalities', {
        params: { search, type: typeSelected },
      });
      const data = response.data.map<IOption>((modality) => ({
        id: modality.id,
        value: modality.name,
        selected: false,
      }));
      setModalities(data);
    },
    [typeSelected]
  );

  useEffect(() => {
    handleLoadModalities();
  }, [handleLoadModalities]);

  useEffect(() => {
    if (onChange) {
      onChange(modalitiesData);
    }
  }, [modalitiesData, onChange]);

  const handleAddModality = useCallback(() => {
    setModalitiesData((oldState) => [
      ...oldState,
      {
        input_id: new Date().getTime(),
      },
    ]);
  }, []);

  const handleRemoveModality = useCallback((input_id) => {
    setModalitiesData((oldState) => {
      if (oldState.length === 1) {
        return [
          {
            input_id: new Date().getTime(),
          },
        ];
      }

      return oldState.filter((modality) => modality.input_id !== input_id);
    });
  }, []);

  const handleChangeModality = useCallback(
    (value, modalityIndex) => {
      const newModalitiesData = modalitiesData.slice();
      newModalitiesData[modalityIndex].name = value;
      handleLoadModalities(value);
    },
    [handleLoadModalities, modalitiesData]
  );

  const handleSelectModality = useCallback(
    (modalitySelected, modalityIndex) => {
      const newModalitiesData = modalitiesData.slice();
      newModalitiesData[modalityIndex].id = modalitySelected.id;
      newModalitiesData[modalityIndex].name = modalitySelected.value;
      setModalitiesData(newModalitiesData);
    },
    [modalitiesData]
  );

  const handleClickAddNewModality = useCallback(
    async (modalityIndex) => {
      const newModalitiesData = modalitiesData.slice();
      const formData = {
        type_id: typeSelected,
        name: newModalitiesData[modalityIndex].name,
      };
      const response = await api.post('modalities', formData);
      newModalitiesData[modalityIndex].id = response.data.id;
      setModalitiesData(newModalitiesData);
    },
    [modalitiesData, typeSelected]
  );

  const handleFocusModality = useCallback(
    (e) => {
      if (e.target.value && e.target.value !== 'Selecione') {
        handleLoadModalities(e.target.value);
      }
    },
    [handleLoadModalities]
  );

  const handleBlurModality = useCallback(() => {
    setTimeout(() => {
      handleLoadModalities();
    }, 200);
  }, [handleLoadModalities]);

  return (
    <Container className={className}>
      <div className="col-12">
        <h2 className="h4 fw-semibold text-center mt-5 mb-4">
          4 - Modalidades
        </h2>
        <p className="mb-4">
          Cadastre ao menos uma modalidade para o seu evento para seguir para o
          próximo passo.
        </p>
      </div>
      <div className="col-12">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="h5 fw-normal roboto d-flex align-items-center">
            <MdOutlineWidgets color="#000" size={20} className="me-1" />
            Modalidades
          </h3>
          {/* <span>
            <IoMdInformationCircleOutline size={16} color="#6C6C6C" />
          </span> */}
        </div>
        {modalitiesData.map((modality, index) => (
          <Modality
            modalities={modalities}
            selectedModality={modality}
            onSelectModality={(data) => handleSelectModality(data, index)}
            onChangeModality={(value) => handleChangeModality(value, index)}
            onFocus={handleFocusModality}
            onBlur={handleBlurModality}
            onClickAddNew={() => handleClickAddNewModality(index)}
            onRemoveModality={() => handleRemoveModality(modality.input_id)}
          />
        ))}
        <button
          type="button"
          className="d-flex align-items-center bd-modality bg-transparent p-2 w-100"
          onClick={handleAddModality}
        >
          <p className="w-100 h6 fw-normal btn-add-modality border-0 d-flex align-items-center mb-0">
            Adicionar nova modalidade
          </p>
          <div className="border-0 bg-transparent h-100">
            <HiPlus size={16} color="#D4D4D4" className="ms-2 " />{' '}
          </div>
        </button>
      </div>
    </Container>
  );
};

export default Modalities;
