/* eslint-disable no-console */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MdCheck } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import { TableList, Container, Filter } from './styles';

import Table, { IColumn } from '~/components/Table';
import FiltersLists from '~/components/FiltersLists';
import ExportCsv from '~/components/ExportCsv';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { IOption } from '~/components/Select';

interface IOrder {
  total: string;
}

interface IUser {
  id: number;
  name: string;
  email: string;
  created_at: string;
  additionalInformation: {
    document?: string;
    phone: string;
    birthdate?: string;
    address: {
      zip_code: string;
      street: string;
      number: number;
      neighborhood: string;
      city: string;
      state: string;
      country: string;
      complement?: string;
    };
  };
  orders: IOrder[];
}

interface IUserResponse {
  data: IUser[];
  from: number;
  to: number;
  total: number;
  pages: number;
}

interface IDataItem {
  id: number;
  name: string;
  email: string;
  phone: string;
  last_move: string;
  city: string;
  state: string;
  registration_date: string;
  events: boolean;
  profiles: boolean;
  active: boolean;
}

interface IStatus {
  id: number;
  name: string;
}

const Users: React.FC = () => {
  const [pageSelected, setPageSelected] = useState(1);
  const [data, setData] = useState<IDataItem[]>([]);
  const [tableData, setTableData] = useState({
    from: 0,
    to: 0,
    total: 0,
    totalPage: 0,
  });
  const [status, setStatus] = useState<IOption[]>([]);
  const [userName, setUserName] = useState<string | undefined>(undefined);
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);
  const [userCity, setUserCity] = useState<string | undefined>(undefined);
  const [userState, setUserState] = useState<string | undefined>(undefined);
  const [statusId, setStatusId] = useState<string | undefined>(undefined);

  useEffect(() => {
    api.get<IStatus[]>('events-status').then((response) => {
      const dataStatus = response.data.map<IOption>((statusData) => ({
        id: statusData.id,
        value: statusData.name,
        selected: false,
      }));
      setStatus(dataStatus);
    });
  }, []);

  const handleLoadUsers = useCallback(
    async (
      pageData,
      name?: string,
      email?: string,
      city?: string,
      state?: string,
      status_id?: string
    ) => {
      const response = await api.get<IUserResponse>(`users/organizers`, {
        params: {
          page: pageData,
          name,
          email,
          city,
          state,
          status_id,
        },
      });

      const dataAux = response.data.data.map<IDataItem>((userData) => {
        const total = userData.orders.reduce((previous, current) => {
          return previous + parseFloat(current.total);
        }, 0);
        return {
          id: userData.id,
          name: userData.name,
          email: userData.email,
          phone: userData.additionalInformation.phone,
          last_move: formatPrice(total),
          city: userData.additionalInformation.address?.city || 'N/A',
          state: userData.additionalInformation.address?.state || 'N/A',
          registration_date: format(
            parseISO(userData.created_at),
            'dd/MM/yyyy'
          ),
          events: true,
          profiles: true,
          active: true,
        };
      });

      setData(dataAux);
      setTableData({
        from: response.data.from,
        to: response.data.to,
        total: response.data.total,
        totalPage: response.data.pages,
      });
    },
    []
  );

  useEffect(() => {
    handleLoadUsers(1);
  }, [handleLoadUsers]);

  const columns = useMemo<IColumn[]>(
    () => [
      {
        name: '#',
        selector: 'id',
        sortable: false,
      },
      {
        name: 'Nome',
        selector: 'name',
        sortable: true,
      },
      {
        name: 'E-mail',
        selector: 'email',
        sortable: true,
      },
      {
        name: 'Fone',
        selector: 'phone',
        sortable: true,
      },
      {
        name: 'Movimentação',
        selector: 'last_move',
        sortable: true,
      },
      {
        name: 'Cidade',
        selector: 'city',
        sortable: true,
      },
      {
        name: 'Estado',
        selector: 'state',
        sortable: true,
      },
      {
        name: 'Data Cadastro',
        selector: 'registration_date',
        sortable: true,
      },
      {
        name: 'Eventos',
        selector: 'events',
        sortable: true,
        cell: () => (
          <button type="button" className="btn-icons border-0">
            <MdCheck size={16} color="#fff" />
          </button>
        ),
      },
      {
        name: 'Perfis',
        selector: 'profiles',
        sortable: true,
        cell: () => (
          <button type="button" className="btn-icons border-0">
            <MdCheck size={16} color="#fff" />
          </button>
        ),
      },
      {
        name: 'Ativo',
        selector: 'active',
        sortable: true,
        cell: () => (
          <button type="button" className="pe-none btn-icons border-0">
            <MdCheck size={16} color="#fff" />
          </button>
        ),
      },
    ],
    []
  );

  const handleChangePage = useCallback(
    (page) => {
      handleLoadUsers(page, userName, userEmail, userCity, userState, statusId);
      setPageSelected(page);
    },
    [handleLoadUsers, statusId, userCity, userEmail, userName, userState]
  );

  const handleSubmitFilters = useCallback(
    (formData) => {
      setUserName(formData.name);
      setUserEmail(formData.email);
      setUserCity(formData.city);
      setUserState(formData.state);
      setStatusId(formData.status !== 'todos' ? formData.status : undefined);
      handleLoadUsers(
        1,
        formData.name,
        formData.email,
        formData.city,
        formData.state,
        formData.status !== 'todos' ? formData.status : undefined
      );
    },
    [handleLoadUsers]
  );

  const handleClickExportCsv = useCallback(async () => {
    const response = await api.get<IUser[]>('users/organizers', {
      params: {
        name: userName,
        email: userEmail,
        city: userCity,
        state: userState,
        status_id: statusId,
      },
    });

    const dataAux = response.data.map<IDataItem>((userData) => {
      const total = userData.orders.reduce((previous, current) => {
        return previous + parseFloat(current.total);
      }, 0);
      return {
        id: userData.id,
        name: userData.name,
        email: userData.email,
        phone: userData.additionalInformation.phone,
        last_move: formatPrice(total),
        city: userData.additionalInformation.address?.city || 'N/A',
        state: userData.additionalInformation.address?.state || 'N/A',
        registration_date: format(parseISO(userData.created_at), 'dd/MM/yyyy'),
        events: true,
        profiles: true,
        active: true,
      };
    });

    return dataAux;
  }, [statusId, userCity, userEmail, userName, userState]);

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="zoom col-12 px-0">
            <div className="bg-white d-sm-flex justify-content-between align-items-center p-4">
              <h1 className="mb-0">Lista de usuários</h1>

              <ExportCsv
                data={data}
                columns={columns}
                fileName="Lista_de_usuarios"
                onClick={handleClickExportCsv}
              />
            </div>
          </div>
          <Filter className="col-12 px-0 mt-3">
            <FiltersLists
              onSubmitFilters={handleSubmitFilters}
              name
              email
              city
              state
              statusEvent
              statusOptions={status}
            />
          </Filter>
          <div className="col-12 px-0">
            <div className="row">
              <TableList className="col-12 mt-3">
                <div className="bg-white pb-2">
                  <div className="bg-white overflow-auto">
                    <div className="">
                      <Table
                        className="mb-0 p-0 table"
                        columns={columns}
                        data={data}
                        pagination
                        totalData={tableData.total}
                        selectedPage={pageSelected}
                        onChangePage={handleChangePage}
                      />
                    </div>
                  </div>
                </div>
              </TableList>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Users;
